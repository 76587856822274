import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from 'react-router';
import Layout from "../../../Components/Layout/Layout";
import { Box, Grid, Typography } from "@mui/material";
import { TransportPricing } from "../../../system/Domain";
import PricingHistory from "./PricingHistory";
import usePricingForm from "./usePricingForm";
import AdminNavigation from "Navigation/AdminNavigation";
import AdminSubMenu from "../AdminSubMenu";
import showMessage from "Dialogs/showMessage";
import PricingPageSidebar from "./PricingPageSidebar";
import PricingView from "./PricingView";
import Tile from "Components/Tiles/Tile";
import ask from "Dialogs/ask";
import confirm from "Dialogs/confirm";

export interface Props {
	pricing: TransportPricing
	startEdit?: boolean | null;
}

export default (props: Props) => {
	const history = useHistory();
	const [pricing, setPricing] = useState<TransportPricing>(props.pricing);
	const [isEdit, setIsEdit] = useState(props.startEdit || false);

	useEffect(() => {
		setPricing(props.pricing)
	}, [props.pricing]);

	const save = async (changedPricing: TransportPricing) => {
		const response = await axios.put(`/api/companies/${pricing.company.name}/transport-pricing`, { ...changedPricing });

		await showMessage({ title: "Informationen", message: "Preise gespeichert." });
		setPricing(response.data);
	};

	const deletePricing = async () => {
		const { yes } = await ask("Sollen die Preise wirklich gelöscht werden?");
		if (yes) {
			await axios.delete(`/api/companies/${pricing.company.name}/transport-pricing`);

			history.push('/transport-pricing');
		}
	};

	const startEdit = () => {
		setIsEdit(true);
	};

	const startView = async () => {
		const canGoView = !getIsDirty() || (await confirm("Alle nicht gespeicherten Änderungen werden verworfen.")).ok;

		if (canGoView) {
			cancelFormChanges();
			setIsEdit(!canGoView);
		}
	};

	const { pricingForm, getIsDirty, cancelChanges: cancelFormChanges } = usePricingForm({ pricing: pricing, onSave: save });

	return (
		<Layout
			title="Überführungskosten"
			navigation={<AdminNavigation />}
			subMenu={<AdminSubMenu />}
			search="disabled"
			sidebar={<PricingPageSidebar isEdit={isEdit} setEdit={startEdit} setView={startView} delete={deletePricing} />}
		>
			<Box display="flex">
				<Typography align="left" variant="h5">{props.pricing?.company.name}</Typography>
			</Box>
			<Box mt={2}>
				<PricingHistory pricing={pricing} />
				<Grid item xs={12} md={6} mt={2}>
					<Tile title="Preise">
						{!isEdit && (
							<PricingView
								pricing={pricing}
							/>
						)}
						{isEdit && (
							pricingForm
						)}
					</Tile>
				</Grid>
			</Box>
		</Layout>
	)
}

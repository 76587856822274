import axios from 'axios';
import moment from 'moment';
import dateFormats from 'system/dateFormats';
import TileContent from 'Components/Tiles/TileContent';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Add, Email, InsertPageBreak, PeopleAlt, Print } from '@mui/icons-material';
import { Box, Chip, IconButton, Table, TableBody, TableCell, TableRow, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import * as colors from '@mui/material/colors';
import { Colgroup } from "../../../../Components/BreakpointStyledComponents";
import ActionButton from '../../../../Components/ActionButton';
import Tile from '../../../../Components/Tiles/Tile';
import TileActions from '../../../../Components/Tiles/TileActions';
import { Sale, ShipmentForm, ShipmentFormStatus } from '../../../../system/Domain';
import TextBlock from 'Components/Layout/TextBlock';
import ask from 'Dialogs/ask';
import useFormPrinting from './useFormPrinting';
import showMessage from 'Dialogs/showMessage';
import { useRecoilState } from 'recoil';
import { pickupAtom } from 'system/atoms/pickup';
import { setUserMessage } from 'system/reducers/userMessage/actions';
import { useDispatch } from 'react-redux';

export interface Props {
	sale: Sale
	onSaleChanged: (sale: Sale) => void
	canChange: boolean
}

const DAPList = ({ sale, onSaleChanged, canChange }: Props) => {
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const [pickup] = useRecoilState(pickupAtom);

	const newShipmentForm = useCallback(async () => {
		setLoading(true);

		const defaultFormValues: Partial<ShipmentForm> = {
			status: ShipmentFormStatus.started,
			keys: {
				workshopKeys: 0,
				remoteControls: 0
			},
			accessories: {
				trailerHitch: {
					keys: 0
				}
			}
		};

		if (pickup) {
			defaultFormValues.pickupDetails = pickup;
		}

		const { data: newSale } = await axios.post<Sale>(`/api/sales/${sale.id}/shipment-form`, defaultFormValues);

		setLoading(false);

		history.push({
			pathname: `/sales/${newSale.id}/shipment-form/${newSale.shipping.shipmentForms.length - 1}`
		});
	}, [sale]);

	const invalidateShipmentForm = async (index: number, sf: ShipmentForm) => {
		const { yes } = await ask(`Wollen Sie das Protokoll vom ${moment(sf.dateCreated).format(dateFormats.dateTime)} wirklich stornieren?`);

		if (yes) {
			setLoading(true);

			const { data: updatedSale } = await axios.put<Sale>(`/api/sales/${sale.id}/shipment-form/${index}/invalidate`, sf);

			setLoading(false);

			onSaleChanged(updatedSale);
		}
	};

	const { printing, printForm } = useFormPrinting();

	const [emailing, setEmailing] = useState(false);
	const emailShipmentForm = useCallback(async (index) => {
		const { yes } = await ask(`Wollen Sie das Protokoll wirklich per E-Mail an den Käufer senden?`)

		if (yes) {
			setEmailing(true);

			try {
				await axios.post(`/api/sales/${sale.id}/shipment-form/${index}/send`);

				await showMessage({ title: "Informationen", message: "Das Protokoll wurde an den Käufer gesendet" });
			} finally {
				setEmailing(false);
			}
		}
	}, [sale]);

	const dispatch = useDispatch();
	const [, setPickup] = useRecoilState(pickupAtom);
	const savePickupCache = useCallback((index) => {
		try {
			const pickup = {
				...sale.shipping.shipmentForms[index].pickupDetails,
				pickupPerson: {
					...sale.shipping.shipmentForms[index].pickupDetails.pickupPerson
				}
			};
			if (pickup.pickupPerson) {
				pickup.pickupPerson.signature = null;
			}
			pickup.senderSignature = null;

			setPickup(pickup);
			dispatch(setUserMessage('Abholperson kopiert', "success"))
		} catch (ex) {
			console.log(ex);
		}
	}, [sale]);

	const canAddNew = !sale.shipping.shipmentForms ||
		(sale.shipping?.shipmentForms &&
			(sale.shipping.shipmentForms.length == 0 || sale.shipping.shipmentForms[sale.shipping.shipmentForms.length - 1].status === ShipmentFormStatus.invalidated));

	const forms = sale.shipping?.shipmentForms && sale.shipping?.shipmentForms
		.map((f, i) => ({ form: f, originalIndex: i }))
		.sort((f1, f2) => f1.form.dateCreated > f2.form.dateCreated ? -1 : 1); // descending order

	return (
		<Tile title="Ausgangsprotokolle">
			{loading && <CircularProgress />}
			{!loading && sale.shipping?.shipmentForms?.length > 0 && (
				<TileContent dense>
					<Table>
						<Colgroup xs>
							<col style={{ width: "80%" }} />
							<col style={{ width: "20%" }} />
						</Colgroup>
						<TableBody>
							{forms.map((sf) => {
								let status = "";
								let color = "";

								switch (sf.form.status) {
									case "Closed": {
										color = colors.green[500];
										status = "Abgeschlossen";
										break;
									}
									case "Invalidated": {
										color = colors.orange[500];
										status = "Storniert";
										break;
									}
									default: {
										status = "In Bearbeitung";
										color = colors.orange[500];
									}
								}

								const title = "Protokoll vom " + moment(sf.form.dateCreated).format(dateFormats.dateTime);
								const dateInformation = (
									<span>
										Erstellungsdatum: {moment(sf.form.dateCreated).format(dateFormats.dateTime)} <br />
										{sf.form.isSigned() && sf.form.status === "Closed" && `Abgeschlossen am: ${moment(sf.form.dateUpdated).format(dateFormats.dateTime)}`}{" "}
										{sf.form.status === "Invalidated" && `Storniert am: ${moment(sf.form.dateUpdated).format(dateFormats.dateTime)}`}
									</span>
								);

								return (
									<TableRow
										hover
										sx={{ cursor: 'pointer' }}
										key={sf.originalIndex}
										onClick={() => history.push({ pathname: `/sales/${sale.id}/shipment-form/${sf.originalIndex}` })}
									>
										<TableCell>
											<TextBlock
												primary={title}
												secondary={dateInformation}
											/>
											<Box mt={2}>
												<Chip
													label={status}
													size="small"
													sx={{ backgroundColor: color }}
												/>
											</Box>
										</TableCell>
										<TableCell
											sx={{
												verticalAlign: "middle",
												textAlign: "right",
												whiteSpace: "nowrap"
											}}
										>
											<>
												<Tooltip title="Abholperson kopieren">
													<IconButton
														sx={{ padding: 1.5 }}
														edge="end"
														onClick={e => {
															savePickupCache(sf.originalIndex);
															e.stopPropagation();
														}}
													>
														<PeopleAlt />
													</IconButton>
												</Tooltip>
												{sf.form.status === ShipmentFormStatus.closed && (
													<Tooltip title="Protokoll zum Käufer schicken">
														<IconButton
															sx={{ padding: 1.5 }}
															edge="end"
															onClick={e => {
																emailShipmentForm(sf.originalIndex);
																e.stopPropagation();
															}}
															disabled={emailing}
														>
															<Email />
														</IconButton>
													</Tooltip>
												)}
												<Tooltip title="Protokoll als PDF">
													<IconButton
														sx={{ padding: 1.5 }}
														edge="end"
														onClick={e => {
															printForm(sale?.id, sf.originalIndex.toString());
															e.stopPropagation();
														}}
														disabled={printing}
													>
														<Print />
													</IconButton>
												</Tooltip>
												{canChange && sf.form.status === ShipmentFormStatus.closed && (
													<Tooltip title="Stornieren">
														<IconButton
															sx={{ padding: 1.5 }}
															edge="end"
															onClick={e => {
																invalidateShipmentForm(sf.originalIndex, sf.form);
																e.stopPropagation();
															}}
														>
															<InsertPageBreak />
														</IconButton>
													</Tooltip>
												)}
											</>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TileContent>
			)}
			{canChange && (
				<TileActions>
					<ActionButton
						variant="text"
						onClick={newShipmentForm}
						disabled={!canAddNew || loading}
						startIcon={<Add />}
					>
						<span>Neues Protokoll erstellen</span>
					</ActionButton>
				</TileActions>
			)}
		</Tile>
	);
};

export default DAPList;

import axios from 'axios';
import moment from 'moment';
import queryString from 'query-string';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useAsync } from 'react-use';
import { Autorenew, Check, Edit } from '@mui/icons-material';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import Actions from '../../../Components/Actions';
import FormDateTimePicker from '../../../Components/FormDateTimePicker';
import InlineList from '../../../Components/InlineList';
import Layout from '../../../Components/Layout/Layout';
import TextBlock from '../../../Components/Layout/TextBlock';
import Plate from '../../../Components/Plate/Plate';
import Tile from '../../../Components/Tiles/Tile';
import TileContent from '../../../Components/Tiles/TileContent';
import { Sale, ShipmentFormStatus, UserRole, Vehicle } from '../../../system/Domain';
import translateError from '../../../system/translateError';
import useForm from '../../../system/useForm';
import SalesAddressInfo from '../../VehicleSales/Orders/SalesAddressInfo';
import useUser from "../../../system/useUser";
import VehicleNavigation from "../../../Navigation/VehicleNavigation";
import dateFormats from "../../../system/dateFormats";
import ask from 'Dialogs/ask';
import Sidebar from 'Components/Sidebar/Sidebar';
import SidebarButton from 'Components/Sidebar/SidebarButton';
import SidebarGroup from 'Components/Sidebar/SidebarGroup';
import ShipmentDocuments from './ShipmentDocuments';
import implementIData from './DAP/implementIData';
import DAPList from './DAP/DAPList';
import { useRecoilState } from 'recoil';
import { pickupAtom } from 'system/atoms/pickup';

interface ShippingForm {
	shippingDate: string
	shippingTime?: string
}

interface Props {
	sale: Sale
	vehicle: Vehicle
}

const ShipVehiclePage = (props: Props) => {
	const location = useLocation();
	const [, , hasRoles] = useUser();
	const [error, setError] = useState<string | null>(null);
	const [sales, setSales] = useState<Sale | null>();
	const [vehicle, setVehicle] = useState<Vehicle | null>(null);
	const [editing, setEditing] = useState<boolean>(false);
	const [shippingForm, setShippingForm] = useState<ShippingForm>({
		shippingDate: moment().toISOString()
	});
	const history = useHistory();

	const dispatch = useDispatch();

	const isLotManager = hasRoles(UserRole.LotManager);

	const form = useForm({
		values: shippingForm,
		setValues: setShippingForm,
		deltaValues: null
	});

	useAsync(async () => {
		setSales(props.sale);
		setVehicle(props.vehicle);

		const query = queryString.parse(location.search);

		if (!query.pickupToken && !query.saleId) return;

		dispatch({ type: "SUSPEND_ERROR_HANDLING" });
		try {
			const { data: sales } = await axios.post<Sale>(`/api/sales/prepare-shipping`, {
				pickupToken: query.pickupToken,
				saleId: query.saleId
			});

			setSales(sales);
		} catch (error) {
			if (error.isAxiosError && error.response.status === 400) {
				setError(translateError(error.response.data));
			} else {
				throw error;
			}
		} finally {
			dispatch({ type: "RESUME_ERROR_HANDLING" });
		}
	}, [location.search]);

	useAsync(async () => {
		if (!sales) {
			setVehicle(null);
			return;
		}

		const { data: newVehicle } = await axios.get<Vehicle>(`/api/vehicles/${sales.vehicle.id}`);
		setVehicle(newVehicle);
	}, [sales]);

	const [, setPickup] = useRecoilState(pickupAtom);

	const removePickupCache = () => setPickup(null);

	const savePickupCache = (sale: Sale) => {
		const pickup = {
			...sale.shipping.shipmentForms[sale.shipping.shipmentForms.length - 1].pickupDetails,
			pickupPerson: {
				...sale.shipping.shipmentForms[sale.shipping.shipmentForms.length - 1].pickupDetails.pickupPerson
			}
		};

		if (pickup.pickupPerson) {
			pickup.pickupPerson.signature = null;
		}

		pickup.senderSignature = null;

		setPickup(pickup);
	}

	const handleConfirmShipping = async () => {
		const { yes } = await ask("Wollen Sie die Auslieferung bestätigen?");
		if (yes) {
			const { data: newSales } = await axios.post<Sale>(`/api/sales/${sales!.id}/confirm-shipping`, shippingForm);

			if (!newSales.shipping.isPaperShipmentForm) {
				const { yes } = await ask("Werden noch weitere Fahrzeuge von der gleichen Person abgeholt?");

				if (yes) {
					savePickupCache(newSales);
				} else {
					removePickupCache();
				}
			}
			setSales(newSales);

			history.push(`/vehicles/${newSales.vehicle.id}`);
		}
	};

	const toggleFormStatus = async () => {
		const { yes } = await ask(sales.shipping?.isPaperShipmentForm ? "Wollen Sie wirklich zum digitalen Ausgangsprotokoll wechseln?" :
			"Wollen Sie wirklich zum manuellen Ausgangsprotokoll wechseln? ACHTUNG: Alle Angaben werden gelöscht!");

		if (yes) {
			const { data: newSales } = await axios.put<Sale>(`/api/sales/${sales!.id}/paper-shipping-form-status`,
				{ IsAllowed: !sales.shipping?.isPaperShipmentForm });

			setSales(newSales);
		}
	};

	const toggleEditingMode = () => {
		setEditing(mode => !mode);
	};

	const salesChanged = useCallback((newSale: Sale) => {
		setSales(newSale)
	}, []);

	const digitalShipmentForm = implementIData(sales?.shipping?.shipmentForms &&
		(sales?.shipping?.shipmentForms?.length > 0) &&
		sales?.shipping?.shipmentForms[sales?.shipping?.shipmentForms?.length - 1]);

	const order = sales?.orders.find(o => o.status === "Paid");

	const lastHistoryEntry = vehicle?.history?.entries[vehicle?.history?.entries.length - 1];

	const canConfirm = vehicle && vehicle.remarketing.status !== "Ausgang" && isLotManager && sales &&
		sales.shipping && (sales.shipping.isPaperShipmentForm || digitalShipmentForm?.status === ShipmentFormStatus.closed);
	const canToggleForm = vehicle && vehicle.remarketing.status !== "Ausgang" && isLotManager &&
		(!digitalShipmentForm || digitalShipmentForm.status === ShipmentFormStatus.started)
	const canChangeForms = vehicle && vehicle.remarketing.status !== "Ausgang" && isLotManager;

	return (
		<Layout
			navigation={<VehicleNavigation vehicle={vehicle} />}
			title={vehicle?.remarketing.status === "Ausgang" ? "Dokumente zur Auslieferung bearbeiten" : "Fahrzeug ausliefern"}
			sidebar={
				<Sidebar>
					<SidebarGroup>
						<SidebarButton
							color="primary"
							startIcon={<Check />}
							disabled={!canConfirm}
							label="Auslieferung bestätigen"
							onClick={handleConfirmShipping}
						/>
					</SidebarGroup>
					{canChangeForms && (
						<SidebarGroup>
							<SidebarButton
								color="primary"
								startIcon={<Autorenew />}
								disabled={!canToggleForm}
								label={sales?.shipping?.isPaperShipmentForm ? "Zum digitalen Protokoll wechseln" : "Zum manuellen Protokoll wechseln"}
								onClick={toggleFormStatus}
							/>
						</SidebarGroup>
					)}
				</Sidebar>
			}
		>
			{error && (
				<Typography color="error">{error}</Typography>
			)}
			{sales && (
				<Box mb={2}>
					<Grid
						container
						spacing={2}
						justifyContent="space-between"
						alignItems="center"
					>
						<Grid item>
							<TextBlock
								primary={<Plate plateNumber={sales.plateNumber} />}
								secondary={
									<InlineList>
										{sales.vin}
										{sales.vehicle.businessLine}
									</InlineList>
								}
							/>
						</Grid>
					</Grid>
				</Box>
			)}
			<Box>
				{sales && (
					<>
						<Box mb={2}>
							<Grid container spacing={1}>
								{vehicle && (
									<Grid item md={12} lg={6} style={{ flexGrow: 1 }}>
										<Tile title="Auslieferung">
											<TileContent>
												<Grid container spacing={1} justifyContent="space-between">
													<Grid item>
														<TextBlock
															primary={vehicle.type?.description}
															secondary={`Status: ${vehicle.remarketing.status} - ${moment(lastHistoryEntry.date).format(dateFormats.dateTime)}`}
														/>
														{!editing && (
															<Box mt={2}>
																<Typography>Auslieferungsdatum</Typography>
																<Typography>{moment(sales.dateShipped || shippingForm.shippingDate).format(dateFormats.dateTime)}</Typography>
															</Box>
														)}
														{editing && (
															<Box mt={2}>
																<FormDateTimePicker
																	name="shippingDate"
																	form={form}
																	label="Auslieferungsdatum"
																	margin="normal"
																	variant="date"
																	format="DD.MM.YYYY"
																	minDate={moment(lastHistoryEntry.date).toISOString()}
																	disableFuture
																/>
																<Box ml={2} sx={{ display: "inline" }}>
																	<FormDateTimePicker
																		name="shippingTime"
																		form={form}
																		label="Auslieferungszeit"
																		variant="time"
																		format="HH:mm"
																		ampm={false}
																		useTimeIcon
																	/>
																</Box>
															</Box>
														)}
													</Grid>
													{isLotManager && sales.status === "ReadyForShipping" && (
														<Grid item>
															<Actions>
																{!editing && (
																	<IconButton
																		size="small"
																		onClick={toggleEditingMode}
																	>
																		<Edit />
																	</IconButton>
																)}
																{editing && (
																	<IconButton onClick={toggleEditingMode}>
																		<Check />
																	</IconButton>
																)}
															</Actions>
														</Grid>
													)}
												</Grid>
											</TileContent>
										</Tile>
									</Grid>
								)}
								{order && (
									<Grid item md={12} lg={6} style={{ flexGrow: 1 }}>
										<Tile title="Käufer">
											<TileContent>
												<SalesAddressInfo address={order.buyer} />
											</TileContent>
										</Tile>
									</Grid>
								)}
							</Grid>
						</Box>

						{!sales.shipping?.isPaperShipmentForm && (
							<Grid container mt={3}>
								<Grid item md={12} lg={6}>
									<DAPList sale={sales} onSaleChanged={salesChanged} canChange={canChangeForms} />
								</Grid>
							</Grid>
						)}

						<ShipmentDocuments
							sale={sales}
							vehicle={vehicle}
							exclude={sales.shipping?.isPaperShipmentForm ? [] : ["ShippingForm"]}
							onSaleChanged={salesChanged}
						/>
					</>
				)}
			</Box>
		</Layout>
	);
};

export default ShipVehiclePage;

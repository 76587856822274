import axios from 'axios';
import { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useAsync } from 'react-use';
import { Print } from '@mui/icons-material';
import { Box } from '@mui/material';
import Layout from '../../../../Components/Layout/Layout';
import TextBlock from '../../../../Components/Layout/TextBlock';
import { EquipmentHighlight, SalesShipping, Vehicle, VehicleType } from '../../../../system/Domain';
import VehicleNavigation from "../../../../Navigation/VehicleNavigation";
import Sidebar from 'Components/Sidebar/Sidebar';
import SidebarButton from 'Components/Sidebar/SidebarButton';
import SidebarGroup from 'Components/Sidebar/SidebarGroup';
import DAPForm from './DAPForm';
import VinDisplay from 'Components/VinDisplay';
import useFormPrinting from './useFormPrinting';

export interface ShippingSalePartial {
	id: string;
	vin: string;
	vehicle: {
		id: string;
		leasmanId: string;
		fleetnetId: string;
		type: VehicleType;
		businessLine: string;
		dateFirstRegistered: string;
		highlights: EquipmentHighlight[];
	};
	plateNumber: string;
	shipping?: SalesShipping | null;
}

const ShipmentFormPage = () => {
	const { id, index } = useParams<{ id: string, index: string }>();
	const [sale, setSale] = useState<ShippingSalePartial | null>();
	const [formIndex, setFormIndex] = useState(-1);
	const [vehicle, setVehicle] = useState<Vehicle | null>(null);
	const history = useHistory();

	useAsync(async () => {
		setFormIndex(parseInt(index.toString()));

		const { data: sale } = await axios.get<ShippingSalePartial>(`/api/sales/${id}`);

		setSale(sale);
	}, [id, index]);

	useAsync(async () => {
		if (!sale) {
			setVehicle(null);
			return;
		}

		const { data: newVehicle } = await axios.get<Vehicle>(`/api/vehicles/${sale.vehicle.id}`);

		setVehicle(newVehicle);
	}, [sale]);

	const formCloses = useCallback((sf) => {
		history.goBack();
	}, []);

	const { printing, printForm } = useFormPrinting();

	return (
		<Layout
			navigation={<VehicleNavigation vehicle={vehicle} />}
			title="Digitales Ausgangsprotokoll"
			plateNumber={sale?.plateNumber}
			sidebar={
				<Sidebar>
					<SidebarGroup>
						<SidebarButton
							color="primary"
							label="Zurück"
							onClick={() => history.goBack()}
						/>
						<SidebarButton
							startIcon={<Print />}
							color="primary"
							label="Protokol drucken"
							disabled={printing}
							onClick={() => printForm(sale?.id, index)}
						/>
					</SidebarGroup>
				</Sidebar>
			}
		>
			<Box sx={{ overflowY: 'auto' }}>
				{sale && (
					<Box mb={2}>
						<TextBlock
							flat
							primary={sale.vehicle.type?.description}
							secondary={<VinDisplay vin={sale.vin} />}
						/>
					</Box>
				)}
				{sale && !sale.shipping?.isPaperShipmentForm && (
					<DAPForm sale={sale} vehicle={vehicle} shipmentFormIndex={formIndex} onFormClosed={formCloses} />
				)}
			</Box>
		</Layout>
	);
};

export default ShipmentFormPage;

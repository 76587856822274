import axios from 'axios';
import { useCallback, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { ReturnForm, Sale, ShipmentForm, ShipmentFormStatus, Vehicle } from '../../../../system/Domain';
import ask from 'Dialogs/ask';
import useForm from 'system/useForm';
import useAsyncEffect from 'system/useAsyncEffect';
import { Step } from '../../../../Components/FormWizard';
import FormWizard from '../../../../Components/FormWizard';
import implementIData from './implementIData';
import DAP01Vin from './DAP01Vin';
import DAP02ConditionAndEquipment from './DAP02ConditionAndEquipment';
import DAP03KeysAndAccessories from './DAP03KeysAndAccessories';
import DAP04Wheels from './DAP04Wheels';
import DAP05RemarksInterior from './DAP05RemarksInterior';
import DAP06RemarksExterior from './DAP06RemarksExterior';
import DAP07RemarksOther from './DAP07RemarksOther';
import DAP08Pickup from './DAP08Pickup';
import DAP09Signatures from './DAP09Signatures';
import showMessage from 'Dialogs/showMessage';
import { useHistory } from 'react-router-dom';
import { ShippingSalePartial } from "./ShipmentFormPage";

interface Props {
	sale: ShippingSalePartial
	vehicle: Vehicle
	shipmentFormIndex: number
	onFormClosed?: (sf: ShipmentForm) => void
}

// always verify the logic of checkActiveStep function (see below) when doing step rearrengement !!!
const steps: Step[] = [
	{
		title: 'Fahrgestellnummer',
		ui: props => <DAP01Vin {...props} />
	},
	{
		title: 'Fahrzeugzustand & Ausstattung',
		ui: props => <DAP02ConditionAndEquipment {...props} />
	},
	{
		title: 'Schlüssel & Weitere Ausstattung',
		ui: props => <DAP03KeysAndAccessories {...props} />
	},
	{
		title: 'Rädern und Felgen',
		ui: props => <DAP04Wheels {...props} />
	},
	{
		title: 'Reklamation Interieur',
		ui: props => <DAP05RemarksInterior {...props} />
	},
	{
		title: 'Reklamation Exterieur',
		ui: props => <DAP06RemarksExterior  {...props} />
	},
	{
		title: 'Reklamation Anderes',
		ui: props => <DAP07RemarksOther  {...props} />
	},
	{
		title: 'Fahrzeugabholung',
		ui: props => <DAP08Pickup {...props} />
	},
	{
		title: 'Unterschriften',
		ui: props => <DAP09Signatures {...props} />
	}
];

const DAPForm = (props: Props) => {
	const [shipmentFormData, setShipmentFormData] = useState(implementIData(props.sale.shipping?.shipmentForms[props.shipmentFormIndex]));

	const [comparisonData, setComparisonData] = useState<ShipmentForm>(null);
	const [isLoading, setIsLoading] = useState(true);
	const history = useHistory();

	// load last ReturnForm data - to show for comparison
	useAsyncEffect(async () => {
		if (!props.vehicle || shipmentFormData?.isReadonly()) {
			// when form is already closed, we don't want to load and show comparison data
			setIsLoading(false);
			return;
		}

		const { data: returnForms } = await axios.post<ReturnForm[]>('/api/orders/transports/return-forms/search',
			{
				vin: props.vehicle.vin
			}
		);

		// (forms search returns them in descendening order - and we want to take the newest one)
		if (returnForms.length > 0) {
			const comparisonShipmentForm: ShipmentForm = {
				status: ShipmentFormStatus.started,
				dateUpdated: returnForms[0].dateUpdated,
				dateCreated: returnForms[0].dateCreated,
				createdBy: returnForms[0].createdBy,
				updatedBy: returnForms[0].updatedBy,
				hasReturnForm: !!returnForms[0],
				conditionAndEquipment: {
					inspectionReports: returnForms[0].readiness.inspectionReports,
					correctVin: returnForms[0].readiness.correctVin,
					mileage: returnForms[0].transfer.mileage,
					serviceBooklet: returnForms[0].documentsAndEquipment.serviceBooklet,
					serviceRecord: returnForms[0].documentsAndEquipment.serviceRecord,
					radio: returnForms[0].documentsAndEquipment.radio,
					navigationDevice: returnForms[0].documentsAndEquipment.navigationDevice,
					navigationDataMedium: returnForms[0].documentsAndEquipment.navigationDataMedium
				},
				keys: {
					vehicleKeys: returnForms[0].documentsAndEquipment.keys
				},
				accessories: {
					bootCover: returnForms[0].accessories.bootCover,
					loadSecuringNet: returnForms[0].accessories.loadSecuringNet,
					isChargingCable: returnForms[0].accessories.chargingCable
				},
				installedTiresAndRims: returnForms[0].installedTiresAndRims,
				additionalTiresAndRims: returnForms[0].additionalTiresAndRims,
				suppliedTiresAndRims: returnForms[0].suppliedTiresAndRims
			};

			setComparisonData(comparisonShipmentForm);
		}

		setIsLoading(false);
	}, [props.vehicle?.vin]);

	const formSave = useCallback(async (sf: ShipmentForm) => {
		const { data: updatedSale } = await axios.put<Sale>(`/api/sales/${props.sale.id}/shipment-form/${props.shipmentFormIndex}`, sf);

		const updatedShipmentForm = updatedSale.shipping.shipmentForms[props.shipmentFormIndex];

		setShipmentFormData(implementIData(updatedShipmentForm));
	}, [props.sale.id]);

	const formClose = useCallback(async (sf: ShipmentForm) => {
		const { yes } = await ask("Wollen Sie das Ausgangsprotokoll abschließen?");

		if (yes) {
			const { data: updatedSale } = await axios.put<Sale>(`/api/sales/${props.sale.id}/shipment-form/${props.shipmentFormIndex}/close`);

			const updatedSf = updatedSale.shipping.shipmentForms[props.shipmentFormIndex];
			setShipmentFormData(implementIData(updatedSf));

			if (props.onFormClosed) {
				props.onFormClosed(updatedSf);
			}
		}
	}, [props.sale.id]);

	const checkActiveStep = useCallback(async (form, active): Promise<boolean> => {
		const isCorrectVin = !!form.getValue("conditionAndEquipment.correctVin");
		if ((active === 0) && !isCorrectVin) {
			// this is Vin step, cannot continue if Vin is not correct
			await showMessage({ title: "Fehler", message: "Fahrgestellnummer muss KORREKT und ÜBERPRÜFT sein.", color: "error" });
			return false;
		}

		return true;
	}, [props.sale.id]);

	const comparisonForm = useForm({
		values: comparisonData,
		setValues: null
	});

	return (
		<Grid item xs={12}>
			{isLoading && (
				<CircularProgress />
			)}
			<FormWizard
				dataObject={shipmentFormData}
				steps={steps}
				onSave={formSave}
				onComplete={formClose}
				onReturn={() => history.goBack()}
				onNextStep={checkActiveStep}
				dirtyFormMessage="Die Änderungen wurden noch nicht gespeichert, beim Verlassen der Seite gehen die Änderungen verloren."
				stepsProps={!shipmentFormData?.isReadonly() ? comparisonForm : null}
			/>
		</Grid>
	);
};

export default DAPForm;

import { Form } from "../../../../system/useForm";
import Grid from "@mui/material/Grid";
import { Box, Typography } from "@mui/material";
import FormText from "Components/FormText";
import FormSignature from "Components/FormSignature";

const DAP09Signatures = ({ form, save }: { form: Form, save: () => void }) => {
	return (
		<>
			<Grid item xs={12}>
				<Typography variant="body1" mt={1}>
					Durch meine Unterschrift bestätige ich die oben getätigten Angaben. Das Fahrzeug wurde andernfalls im Zustand laut Kaufvertrag übernommen
					(Inhalt Gutachten).
				</Typography>
				<Typography variant="body1" mt={1}>
					Das Fahrzeug ist nicht aufbereitet und in einem gebrauchsfähigen Zustand übernommen worden. Sofern nicht durchs Gutachten anders
					beschrieben.
				</Typography>
				<Typography variant="body1" mt={1}>
					Der Speicherung meiner angegebenen Daten und Kopien von Ausweisdokumenten stimme ich unter den geltenden gesetzlichen Bestimmungen und
					Aufbewahrungsfristen zu.
				</Typography>
			</Grid>
			<FormSignature
				name="pickupDetails.pickupPerson.signature"
				label={`${form.getValue("pickupDetails.pickupPerson.firstName") ?? ""} ${form.getValue("pickupDetails.pickupPerson.lastName") ?? ""}`}
				form={form}
				options={{ required: true }}
				onSign={save}
				onReset={save}
				allowSign={!form.readOnly}
				allowRemoval={!form.readOnly}
			/>
			<Grid item xs={12}>
				<Typography variant="subtitle1" sx={{ fontWeight: 'bolder' }} mt={2}>DEKRA/ALD</Typography>
			</Grid>
			<Grid item xs={6} lg={4}>
				<Grid container>
					<FormText
						name={"pickupDetails.senderInfo.firstName"}
						label="Vorname"
						form={form}
						options={{
							required: true
						}}
						fullWidth
					/>
				</Grid>
			</Grid>
			<Grid item xs={6} lg={8}></Grid>
			<Grid item xs={6} lg={4}>
				<Grid container>
					<FormText
						name={"pickupDetails.senderInfo.lastName"}
						label="Nachname"
						form={form}
						options={{
							required: true
						}}
						fullWidth
					/>
				</Grid>
			</Grid>
			<Grid item xs={6} lg={8}></Grid>
			<FormSignature
				name="pickupDetails.senderSignature"
				label={`${form.getValue("pickupDetails.senderInfo.firstName") ?? ""} ${form.getValue("pickupDetails.senderInfo.lastName") ?? ""}`}
				form={form}
				options={{ required: true }}
				onSign={save}
				onReset={save}
				allowSign={!form.readOnly}
				allowRemoval={!form.readOnly} />
		</>
	);
};

export default DAP09Signatures;

import { Box, Grid } from "@mui/material";
import { PropsWithChildren, ReactNode } from "react";

interface Props {
	leftHeadline?: ReactNode
	left: ReactNode
	rightHeadline?: ReactNode
	right: ReactNode
}

const TwoSidedBox = ({ leftHeadline, left, rightHeadline, right }: PropsWithChildren<Props>) => {
	return (
		<Grid container>
			<Grid item xs={6} lg={4}>
				{leftHeadline && (
					<Box>
						{leftHeadline}
					</Box>
				)}
				{left}
			</Grid>
			<Grid item xs={6} lg={8}>
				{rightHeadline && (
					<Box>
						{rightHeadline}
					</Box>
				)}
				{right}
			</Grid>
		</Grid>
	)
}

export default TwoSidedBox;

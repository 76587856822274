import FormChoices from "../../../../Components/FormChoices";
import FormYesNo from "../../../../Components/FormYesNo";
import { Form } from "../../../../system/useForm";
import FormNumber from "Components/FormNumber";
import { Grid, Typography } from "@mui/material";
import EmptySpace from "../EmptySpace";
import TwoSidedBox from "./TwoSidedBox";
import moment from "moment";
import dateFormats from "system/dateFormats";
import EmptyElement from "../../../../Components/EmptyElement";
import TitleElement from "../../../../Components/TitleElement";

const DAP03KeysAndAccessories = ({ form, stepsProps }: { form: Form, stepsProps: any }) => {
	const rightSideInfo = stepsProps && `Angaben Eingang vom ${moment(stepsProps.getValue("dateUpdated")).format(dateFormats.date)}`;
	const editTrailerKeys = form.getValue("accessories.trailerHitch.hitch") === "Permanent" || (form.getValue("accessories.trailerHitch.hitch") === "Removable");
	const hasReturnForm = stepsProps?.getValue("hasReturnForm") === true;

	return (
		<Grid item xs={12}>
			<TwoSidedBox
				leftHeadline={rightSideInfo && hasReturnForm && (
					<EmptyElement title={rightSideInfo} />
				)}
				left={
					<Grid container spacing={4}>
						<Grid item xs={12}>
							<Typography variant="subtitle1" sx={{ fontWeight: 'bolder' }}>Fahrzeugschlüssel</Typography>
						</Grid>
						<FormNumber
							name="keys.vehicleKeys"
							label="Anzahl Schlüssel"
							form={form}
							options={{
								required: true
							}}
						/>
						<FormNumber
							name="keys.workshopKeys"
							label="Anzahl Werkstattschlüssel"
							form={form}
							options={{
								required: true
							}}
						/>
						<FormNumber
							name="keys.remoteControls"
							label="Anzahl Fernbedienungen"
							form={form}
							options={{
								required: true
							}}
						/>
						<Grid item xs={12}>
							<Typography variant="subtitle1" sx={{ fontWeight: 'bolder' }} mt={4}>Weitere Ausstattung</Typography>
						</Grid>
						<FormYesNo
							name="accessories.bootCover"
							label="Kofferraum-Abdeckung vorhanden"
							form={form}
							options={{
								required: true
							}}
						/>
						<FormYesNo
							name="accessories.loadSecuringNet"
							label="Netztrennwand vorhanden"
							form={form}
							options={{
								required: true
							}}
						/>
						<FormYesNo
							name="accessories.isChargingCable"
							label="Ladekabel vorhanden"
							form={form}
							options={{
								required: true
							}}
						/>
						{form.getValue("accessories.isChargingCable") && (
							<FormNumber
								name="accessories.chargingCables"
								label="Anzahl Ladekabel"
								form={form}
								options={{
									required: true
								}}
							/>
						)}
						<Grid item xs={12}>
							<Typography variant="subtitle1" sx={{ fontWeight: 'bolder' }} mt={4}>Anhängerkupplung</Typography>
						</Grid>
						<FormChoices
							name="accessories.trailerHitch.hitch"
							label="Forhanded und Baurt"
							choices={{
								"None": "Ohne",
								"Permanent": "Fest",
								"Removable": "Abhehmbar"

							}}
							form={form}
							options={{
								required: true
							}}
						/>
						{editTrailerKeys && (
							<FormNumber
								name="accessories.trailerHitch.keys"
								label="Anzahl Schlüssel"
								form={form}
								options={{
									required: true
								}}
							/>
						)}
					</Grid>
				}
				rightHeadline={rightSideInfo && hasReturnForm && (
					<TitleElement title={rightSideInfo} />
				)}
				right={stepsProps && hasReturnForm && (
					<Grid container spacing={4}>
						<Grid item xs={12}>
							<EmptySpace typVariant="subtitle1" />
						</Grid>
						<FormNumber
							name="keys.vehicleKeys"
							label="Anzahl Schlüssel"
							form={stepsProps}
							readOnly
						/>
						<FormNumber
							name="keys.workshopKeys"
							label="Anzahl Werkstattschlüssel"
							form={stepsProps}
							readOnly
						/>
						<FormNumber
							name="keys.remoteControls"
							label="Anzahl Fernbedienungen"
							form={stepsProps}
							readOnly
						/>
						<Grid item xs={12}>
							<EmptySpace variant="subtitle1" mt={4} />
						</Grid>
						<FormYesNo
							name="accessories.bootCover"
							label="Kofferraum-Abdeckung vorhanden"
							form={stepsProps}
							disabled
						/>
						<FormYesNo
							name="accessories.loadSecuringNet"
							label="Netztrennwand vorhanden"
							form={stepsProps}
							disabled
						/>
						<FormYesNo
							name="accessories.isChargingCable"
							label="Ladekabel vorhanden"
							form={stepsProps}
							disabled
						/>
					</Grid>
				)}
			/>
		</Grid>
	);
};

export default DAP03KeysAndAccessories;

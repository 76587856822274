import { ReactNode, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Prompt } from 'react-router';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import {
	Box, Button, Divider, Grid, MobileStepper,
	Step, StepContent, StepLabel, Stepper, useTheme
} from '@mui/material';
import useAutoResetState from '../system/useAutoResetState';
import useForm, { Form } from '../system/useForm';
import _ from 'lodash';

export interface Step {
	title: string
	ui: (props: any) => ReactNode
}

interface DataModel {
	isReadonly?: () => boolean
	isValid?: () => boolean
}

export interface FormWizardProps<DataType extends DataModel> {
	dataObject: DataType
	dirtyFormMessage: string
	onSave: (d: DataType) => Promise<void>
	onComplete: (d: DataType) => void
	onReturn?: () => void
	onNextStep?: (form: Form, active: number, next: number) => Promise<boolean>
	steps: Step[]
	stepsProps: any
}

/*
 * Parent component must set overflowY: 'auto' (in order for the footer to work correctly)
 * Auto save of changed data after 5 seconds
 */
const FormWizard = <DataType extends DataModel>({
													 dataObject,
													 dirtyFormMessage,
													 onSave,
													 onComplete,
													 onReturn,
													 onNextStep,
													 steps,
													 stepsProps
												 }: FormWizardProps<DataType>) => {
	const theme = useTheme();
	const [editData, setEditData] = useState<DataType>(null); // no need to init, will be set later
	const [saving, setSaving] = useState(false);
	const [savedData, setSavedData] = useState<DataType>(null); // as above

	useEffect(() => {
		if (!dataObject) return;

		setEditData(_.cloneDeep(dataObject));
		setSavedData(_.cloneDeep(dataObject));
	}, [dataObject]);

	const requestSave = async () => {
		if (saving) return;
		if (_.isEqual(editData, savedData)) return;

		setSaving(true);
		setSavedData(_.cloneDeep(editData));
		await onSave(editData)
		setSaving(false);

		if (autoSaveRef.current) {
			clearTimeout(autoSaveRef.current);
		}
	};

	// auto save after 5s of inactivity - when data edited
	const timeoutMs = 5000;
	const autoSaveRef = useRef<NodeJS.Timeout | null>(null); // we use ref to avoid component re-render (like it was when using state)
	useEffect(() => {
		return () => {	// destroy timeout on component destroy
			if (autoSaveRef.current) {
				clearTimeout(autoSaveRef.current);
			}
		};
	}, []);

	useEffect(() => {
		if (_.isEqual(editData, savedData)) return;

		if (autoSaveRef.current) {
			clearTimeout(autoSaveRef.current);
		}

		autoSaveRef.current = setTimeout(requestSave, timeoutMs);
	}, [editData]);

	const maxStepReached = useRef(0);
	const [activeStep, setActiveStep] = useState(0);
	const [validationFailed, setValidationFailed] = useAutoResetState(false, 1000); //"refresh" the validation state every 1sec - to OK - so that we enable Next button

	const form = useForm({
		values: editData,
		setValues: setEditData
	});

	useEffect(() => {
		// check we have data passed of certain type i.e. with isReadonly function
		if (!dataObject.isReadonly) return;

		const readOnly = dataObject.isReadonly();

		if (form.readOnly !== readOnly) {
			form.setReadOnly(readOnly);
		}
	}, [form.readOnly, dataObject]);

	const gotoStep = useCallback((step: ((prevState: number) => number) | number) => {
		setActiveStep(step);
	}, []);

	const gotoPreviousStep = useCallback(() => {
		gotoStep(s => Math.max(0, s - 1));
	}, []);

	const jumpToStep = useCallback((step: number) => {
		const validated = form.validateAll();

		if (validated && (step <= maxStepReached.current)) {
			requestSave(); // TODO MAX AWAIT
			setActiveStep(step);
		} else {
			setValidationFailed(true);
		}
	}, [form.validateAll]);

	const gotoNextStep = useCallback(async () => {
		const validated = form.validateAll();

		if (validated) {
			requestSave(); // TODO MAX AWAIT

			const calcNextStep = s => Math.min(s + 1, steps.length - 1);

			const nextStep = calcNextStep(activeStep);
			if (nextStep != activeStep) {
				if (onNextStep) {
					const canDoNext = await onNextStep(form, activeStep, nextStep);
					if (!canDoNext) {
						return;
					}
				}
				maxStepReached.current = Math.max(maxStepReached.current, nextStep);
				gotoStep(calcNextStep);
			}
		} else {
			setValidationFailed(true);
		}
	}, [form.validateAll]);

	const stepperRef = useRef<any>();
	useLayoutEffect(() => {
		if (!stepperRef.current) {
			return;
		}

		const activeStepUi = stepperRef.current.querySelector(`#step-anchor-${activeStep}`);
		if (activeStepUi) {
			window.setTimeout(() => {
				activeStepUi.scrollIntoView({ behavior: 'smooth' });
			}, 50);
		}
	}, [activeStep]);

	const isFirstStep = activeStep === 0;
	const isLastStep = activeStep === steps.length - 1;

	const showWizard = dataObject.isReadonly && !dataObject.isReadonly();

	const handleNextStep = () => {
		if (!isLastStep) {
			gotoNextStep();
		} else {
			complete();
		}
	};

	const complete = useCallback(() => {
		const validated = form.validateAll();
		if (validated) {
			requestSave(); // TODO MAX await
			onComplete(editData);
		} else {
			setValidationFailed(true);
		}
	}, [form.validateAll, onComplete]);

	const footer = dataObject && showWizard && (
		<Box sx={{ position: 'sticky', bottom: '0' }}>
			<Box sx={{ width: '95%', margin: '0 auto' }}>
				<Divider />
			</Box>
			<MobileStepper
				variant="progress"
				steps={steps.length}
				position="static"
				activeStep={activeStep}
				nextButton={
					<Button
						size="small"
						disabled={saving || (isLastStep && !onComplete)}
						onClick={handleNextStep}
						sx={[
							{
								transition: "color 200ms ease-out"
							},
							(validationFailed && { color: theme.palette.error.main })
						]}
					>
						{!isLastStep ? "Weiter" : "Fertig"}
						<KeyboardArrowRight />
					</Button>
				}
				backButton={
					<Button
						size="small"
						disabled={isFirstStep && !onReturn}
						onClick={isFirstStep ? onReturn : gotoPreviousStep}
						sx={{ transition: "color 200ms ease-out" }}
					>
						<KeyboardArrowLeft />
						Zurück
					</Button>
				}
			/>
		</Box>
	);

	return (
		<>
			<Box>
				{dataObject && (
					<Grid container>
						<Grid item xs={12}>
							<Box width="100%">
								<Stepper
									ref={stepperRef}
									activeStep={activeStep}
									orientation="vertical"
									nonLinear // lets us control the cursors shape
									sx={{
										padding: 0,
										background: 'none'
									}}
								>
									{steps.map((step, index) => (
										<Step
											key={index}
											completed={!showWizard ? false : maxStepReached.current >= index}
											active={!showWizard ? true : activeStep === index}
										>
											<StepLabel
												onClick={() => jumpToStep(index)}
												sx={index <= maxStepReached.current ? { cursor: "pointer" } : { cursor: "default" }}
											>
												<Box
													id={`step-anchor-${index}`}
													sx={{
														top: -theme.spacing(2),
														position: "relative"
													}}
												/>
												{step.title}
											</StepLabel>
											<StepContent
												sx={{
													paddingTop: theme.spacing(2)
												}}
												transitionDuration={0}
											>
												<Box mb={2}>
													<Grid container spacing={4}>
														{step.ui({
															editData,
															form,
															save: requestSave,
															unsavedChanges: !_.isEqual(editData, savedData),
															stepsProps
														})}
													</Grid>
												</Box>
											</StepContent>
										</Step>
									))}
								</Stepper>
							</Box>
						</Grid>
					</Grid>
				)}
			</Box>
			{footer}
			<Prompt
				when={!!dataObject && !_.isEqual(editData, savedData)}
				message={dirtyFormMessage}
			/>
		</>
	);
}

export default FormWizard;


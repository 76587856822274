import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Avatar, Box, Button, Card, CardContent, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import handleDownload from "../../../system/handleDownload";
import { Sale, SalesShipping } from "../../../system/Domain";
import { getDocumentMetaData } from "./SalesDocumentCard";
import moment from "moment";
import formatNumber from "../../../system/formatNumber";
import ListItemChips from "Components/ListItemChips";
import ListItemChip from "Components/ListItemChip";

interface Props {
	sale: Sale
	shipping: SalesShipping
}

const ShippingCard = ({ sale, shipping }: Props) => {
	const history = useHistory();
	const [showDeleted, setShowDeleted] = useState(false);
	const documents = shipping.documents?.filter(d => d.isCurrent);
	const shippingDate = moment(sale.dateShipped);

	const deletedDocuments = shipping.documents?.filter(d => !d.isCurrent);

	const gotoAfterShipping = () => {
		history.push({ pathname: `/vehicles/${sale.vehicle.id}/after-shipping` })
	}
	return (
		<Card>
			<CardContent>
				<Box mb={2}>
					<Typography
						variant="h6"
					>
						Auslieferung
					</Typography>
					{shipping.shippedBy && (
						<Typography variant="body2">Ausgeliefert von {shipping.shippedBy.name} am {shippingDate.format("L")}</Typography>
					)}
				</Box>
				<Box>
					<ListItemChips>
						<ListItemChip
							label={`${shipping.shipmentForms?.length ?? 0} ${shipping.shipmentForms?.length > 1 ? "Übergabeprotokolle" : "Übergabeprotokoll"}`}
							color={"success"}
							active={false}
							onClick={gotoAfterShipping}
						/>

					</ListItemChips>
					{documents && documents.length > 0 && (
						<List>
							{documents.map((d, i) => {
								const documentMetaData = getDocumentMetaData(d.documentType);
								return (
									<ListItem
										key={i}
										dense
										onClick={handleDownload(`/api/sales/${sale.id}/shipping-documents/${d.file.hash}`)}
									>
										<ListItemAvatar>
											<Avatar variant="rounded">
												{documentMetaData.icon}
											</Avatar>
										</ListItemAvatar>
										<ListItemText
											primary={documentMetaData.label}
											secondary={d.file.filename}
										/>
									</ListItem>
								);
							})}
							{deletedDocuments.length > 0 && (
								<Box mt={2}>
									<Button
										variant="text"
										size="small"
										onClick={() => setShowDeleted(s => !s)}
									>
										{formatNumber(deletedDocuments.length)} nachträglich gelöschte Dokumente {showDeleted ? "ausblenden" : "anzeigen"}
									</Button>
								</Box>
							)}
							{showDeleted && deletedDocuments.map((d, i) => {
								const documentMetaData = getDocumentMetaData(d.documentType);
								return (
									<ListItem
										key={i}
										sx={{
											opacity: 0.4,
											"&:hover": {
												opacity: 1
											}
										}}
										dense
										onClick={handleDownload(`/api/sales/${sale.id}/shipping-documents/${d.file.hash}`)}
									>
										<ListItemAvatar>
											<Avatar variant="rounded">
												{documentMetaData.icon}
											</Avatar>
										</ListItemAvatar>
										<ListItemText
											primary={documentMetaData.label}
											secondary={d.file.filename}
										/>
									</ListItem>
								);
							})}
						</List>
					)}
					{((shipping.shipmentForms?.length === 0) && (!documents || documents.length === 0)) && (
						<Box mt={1}>
							<Typography>Keine Dokumente vorhanden</Typography>
						</Box>
					)}
				</Box>
			</CardContent>
		</Card>

	);
};

export default ShippingCard;

import FormYesNo from "../../../../Components/FormYesNo";
import { Form } from "../../../../system/useForm";
import FormNumber from "Components/FormNumber";
import TwoSidedBox from "./TwoSidedBox";
import { Grid } from "@mui/material";
import moment from "moment";
import dateFormats from "system/dateFormats";
import EmptyElement from "../../../../Components/EmptyElement";
import TitleElement from "../../../../Components/TitleElement";

const DAP02ConditionAndEquipment = ({ form, stepsProps }: { form: Form, stepsProps: any }) => {
	const rightSideInfo = stepsProps && `Angaben Eingang vom ${moment(stepsProps.getValue("dateUpdated")).format(dateFormats.date)}`;
	const hasReturnForm = stepsProps?.getValue("hasReturnForm") === true;

	return (
		<Grid item xs={12}>
			<TwoSidedBox
				leftHeadline={rightSideInfo && hasReturnForm && (
					<EmptyElement title={rightSideInfo} />
				)}
				left={
					<Grid container spacing={4}>
						<FormNumber
							name="conditionAndEquipment.mileage"
							label="KM Stand"
							form={form}
							options={{
								required: true
							}}
						/>
						<FormYesNo
							name="conditionAndEquipment.radio"
							label="Radio vorhanden"
							form={form}
							options={{
								required: true
							}}
						/>
						<FormYesNo
							name="conditionAndEquipment.navigationDevice"
							label="Navigationsgerät"
							form={form}
							options={{
								required: true
							}}
						/>
						<FormYesNo
							name="conditionAndEquipment.navigationDataMedium"
							label="Navigations DVD/CD/SD"
							form={form}
							options={{
								required: true
							}}
						/>
						<FormYesNo
							name="conditionAndEquipment.serviceBooklet"
							label="Serviceheft vorhanden"
							form={form}
							options={{
								required: true
							}}
						/>
						<FormYesNo
							name="conditionAndEquipment.serviceRecord"
							label="Servicenachweise vorhanden"
							form={form}
							options={{
								required: true
							}}
						/>
						<FormYesNo
							name="conditionAndEquipment.inspectionReports"
							label="Prüfberichte vorhanden"
							form={form}
							options={{
								required: true
							}}
						/>
					</Grid>
				}
				rightHeadline={rightSideInfo && hasReturnForm && (
					<TitleElement title={rightSideInfo} />
				)}
				right={stepsProps && hasReturnForm && (
					<Grid container spacing={4}>
						<FormNumber
							name="conditionAndEquipment.mileage"
							label="KM Stand"
							form={stepsProps}
							readOnly
						/>
						<FormYesNo
							name="conditionAndEquipment.radio"
							label="Radio vorhanden"
							form={stepsProps}
							disabled
						/>
						<FormYesNo
							name="conditionAndEquipment.navigationDevice"
							label="Navigationsgerät"
							form={stepsProps}
							disabled
						/>
						<FormYesNo
							name="conditionAndEquipment.navigationDataMedium"
							label="Navigations DVD/CD/SD"
							form={stepsProps}
							disabled
						/>
						<FormYesNo
							name="conditionAndEquipment.serviceBooklet"
							label="Serviceheft vorhanden"
							form={stepsProps}
							disabled
						/>
						<FormYesNo
							name="conditionAndEquipment.serviceRecord"
							label="Servicenachweise vorhanden"
							form={stepsProps}
							disabled
						/>
						<FormYesNo
							name="conditionAndEquipment.inspectionReports"
							label="Prüfberichte vorhanden"
							form={stepsProps}
							disabled
						/>
					</Grid>
				)}
			/>
		</Grid>
	);
};

export default DAP02ConditionAndEquipment;

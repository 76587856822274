import { Typography, TypographyProps, TypographyPropsVariantOverrides } from "@mui/material"
import { OverridableStringUnion } from '@mui/types';
import { Variant } from "@mui/material/styles/createTypography";

export interface Props extends TypographyProps {
	typVariant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>
}

const EmptySpace = ({ typVariant, ...rest }: Props) => {
	return (
		<Typography variant={typVariant} sx={{ visibility: 'hidden' }} {...rest} >_</Typography>
	);
};

export default EmptySpace;

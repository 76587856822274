import FormYesNo from "../../../../Components/FormYesNo";
import { Form } from "../../../../system/useForm";
import Box from "@mui/material/Box";
import TwoSidedBox from "./TwoSidedBox";
import dateFormats from "system/dateFormats";
import moment from "moment";
import EmptyElement from "../../../../Components/EmptyElement";
import TitleElement from "../../../../Components/TitleElement";
import { Grid } from "@mui/material";

const DAP01Vin = ({ form, stepsProps }: { form: Form, stepsProps: any }) => {
	const rightSideInfo = stepsProps && `Angaben Eingang vom ${moment(stepsProps.getValue("dateUpdated")).format(dateFormats.date)}`;
	const hasReturnForm = stepsProps?.getValue("hasReturnForm") === true;

	return (
		<Grid item xs={12}>
			<TwoSidedBox
				leftHeadline={rightSideInfo && hasReturnForm && (
					<EmptyElement title={rightSideInfo} />
				)}
				left={
					<Box>
						<FormYesNo
							name="conditionAndEquipment.correctVin"
							label="Korrekte FIN"
							form={form}
							options={{
								required: true
							}}
						/>
					</Box>
				}
				rightHeadline={rightSideInfo && hasReturnForm && (
					<TitleElement title={rightSideInfo} />
				)}
				right={stepsProps && hasReturnForm && (
					<Box>
						<FormYesNo
							name="conditionAndEquipment.correctVin"
							label="Korrekte FIN"
							form={stepsProps}
							disabled
						/>
					</Box>
				)}
			/>
		</Grid>
	);
};

export default DAP01Vin;

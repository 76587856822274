import FormChoices from "../../../../Components/FormChoices";
import { Form } from "../../../../system/useForm";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import FormText from "Components/FormText";
import FormDateTimePicker from "Components/FormDateTimePicker";
import dateFormats from "system/dateFormats";
import FormElement from "../../../../Components/FormElement";

const DAP08Pickup = ({ form, stepsProps }: { form: Form, stepsProps: any }) => {
	return (
		<>
			<FormChoices
				name="pickupDetails.type"
				label="Abholer"
				choices={{
					"Logistic": "Spedition",
					"Buyer": "Aufkäufer",
					"ShippingAttorney": "Bevollmächtigter"
				}}
				form={form}
				options={{
					required: true
				}}
			/>
			{form.getValue("pickupDetails.type") === "Logistic" && (
				<Grid item xs={6}>
					<Grid container spacing={4}>
						<FormText
							name={"pickupDetails.logisticCompanyName"}
							label="Name der Spedition"
							form={form}
							options={{
								required: true
							}}
							fullWidth
						/>
					</Grid>
				</Grid>
			)}
			{form.getValue("pickupDetails.type") === "Logistic" && (
				<FormText
					name={"pickupDetails.lkwPlateNumber"}
					label="Kennzeichen LKW Abholer"
					form={form}
					options={{
						required: true
					}}
				/>
			)}
			<Grid item xs={12}>
				<Typography variant="subtitle1" sx={{ fontWeight: 'bolder' }} mt={4}>Ausweisdokument</Typography>
			</Grid>
			<FormChoices
				name="pickupDetails.pickupPerson.idCard.type"
				label="Ausweisdokument"
				choices={{
					"IDCard": "Personalausweis",
					"Passport": "Reisepass",
					"Other": "Anderes"
				}}
				form={form}
				options={{
					required: true
				}}
			/>
			<Grid item xs={12}>
				<Grid container spacing={4}>
					<Grid item xs={4}>
						<FormText
							name={"pickupDetails.pickupPerson.idCard.number"}
							label="Nummer"
							form={form}
							options={{
								required: true
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={4}>
						<FormElement label="Gültigkeit">
							<FormDateTimePicker
								form={form}
								name="pickupDetails.pickupPerson.idCard.validTill"
								label="Gültig bis"
								variant="date"
								format={dateFormats.date}
								required={false}
								openTo={"day"}
								views={["year", "day"]}
								fullWidth
							/>
						</FormElement>
					</Grid>
					<Grid item xs={4}></Grid>
					<Grid item xs={4}>
						<FormText
							name={"pickupDetails.pickupPerson.idCard.issuer"}
							label="Austellende Behörde"
							form={form}
							options={{
								required: false
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={4}>
						<FormText
							name={"pickupDetails.pickupPerson.nationality"}
							label="Nationalität"
							form={form}
							options={{
								required: true
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={4}></Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="subtitle1" sx={{ fontWeight: 'bolder' }} mt={4}>Anschrift Abholer</Typography>
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={4}>
					<Grid item xs={4}>
						<FormText
							name={"pickupDetails.pickupPerson.firstName"}
							label="Vorname"
							form={form}
							options={{
								required: true
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={4}>
						<FormText
							name={"pickupDetails.pickupPerson.lastName"}
							label="Nachname"
							form={form}
							options={{
								required: true
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={4}></Grid>
					<Grid item xs={4}>
						<FormText
							name={"pickupDetails.pickupPerson.street"}
							label="Straße"
							form={form}
							options={{
								required: false
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={4}>
						<FormText
							name={"pickupDetails.pickupPerson.city"}
							label="Ort"
							form={form}
							options={{
								required: false
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={4}></Grid>
					<Grid item xs={4}>
						<FormText
							name={"pickupDetails.pickupPerson.zipCode"}
							label="PLZ"
							form={form}
							options={{
								required: false
							}}
							fullWidth
						/>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default DAP08Pickup;

import axios from 'axios';
import { ReactNode, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Prompt, RouteComponentProps } from 'react-router';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import {
	Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, MobileStepper,
	Step, StepContent, StepLabel, Stepper, useTheme
} from '@mui/material';
import Layout from '../../../../Components/Layout/Layout';
import { ReturnForm as ReturnFormType } from '../../../../system/Domain';
import extendReturnForm from '../../../../system/extendReturnForm';
import useAsyncEffect from '../../../../system/useAsyncEffect';
import useAutoResetState from '../../../../system/useAutoResetState';
import useBackgroundApi from '../../../../system/useBackgroundApi';
import useForm from '../../../../system/useForm';
import useLog from '../../../../system/useLog';
import useOrderFromMatch from '../../../../system/useOrderFromMatch';
import useTimeout from '../../../../system/useTimeout';
import OrderHeader from '../TransportOrderHeader';
import { statusSettings } from '../TransportOrderStatus';
import Readiness from './ReturnForm-01-Readiness';
import DocumentsAndEquipment from './ReturnForm-02-DocumentsAndEquipment';
import ImpedingConditions from './ReturnForm-03-ImpedingConditions';
import VehiclePictures from './ReturnForm-04-VehiclePictures';
import InstalledTiresAndRims from './ReturnForm-05-InstalledTiresAndRims';
import SuppliedTiresAndRims from './ReturnForm-06-SuppliedTiresAndRims';
import Accessories from './ReturnForm-07-Accessories';
import PreviousDamages from './ReturnForm-08-PreviousDamages';
import DamagesInterior from './ReturnForm-09-DamagesInterior';
import DamagesExterior from './ReturnForm-10-DamagesExterior';
import Transfer from './ReturnForm-11-Transfer';
import Signatures from './ReturnForm-12-Signatures';
import ReturnFormSidebar from './ReturnFormSidebar';
import { isDorfmarkAddress } from "../../../../system/isDorfmarkAddress";
import OrdersNavigation from "../../../../Navigation/OrdersNavigation";

interface IStep {
	title: string
	ui: (props: any) => ReactNode
};

const steps: IStep[] = [
	{
		title: 'Betriebsbereitschaft',
		ui: props => <Readiness {...props} />
	},
	{
		title: 'Fahrzeug Dokumente & Ausstattung',
		ui: props => <DocumentsAndEquipment {...props} />
	},
	{
		title: 'Erschwerte Aufnahme',
		ui: props => <ImpedingConditions {...props} />
	},
	{
		title: 'Fahrzeugbilder',
		ui: props => <VehiclePictures {...props} />
	},
	{
		title: 'Montierte Reifen und Felgen',
		ui: props => <InstalledTiresAndRims {...props} />
	},
	{
		title: 'Mitabgegebene Reifen und Felgen',
		ui: props => <SuppliedTiresAndRims {...props} />
	},
	{
		title: 'Zubehör (Kofferraum / Außen)',
		ui: props => <Accessories {...props} />
	},
	{
		title: 'Unfallvorschäden',
		ui: props => <PreviousDamages {...props} />
	},
	{
		title: 'Schadensaufnahme Interieur',
		ui: props => <DamagesInterior {...props} />
	},
	{
		title: 'Schadensaufnahme Exterieur',
		ui: props => <DamagesExterior {...props} />
	},
	{
		title: 'Übergabe',
		ui: props => <Transfer {...props} />
	},
	{
		title: 'Unterschriften',
		ui: props => <Signatures {...props} />
	}
];

export interface ReturnFormProps extends RouteComponentProps<any> {
}

const ReturnForm = ({ history, match }: ReturnFormProps) => {
	const theme = useTheme();
	const [order] = useOrderFromMatch();
	const [loading, setLoading] = useState(!!match.params.formId);
	const [returnForm, setReturnForm] = useState<ReturnFormType>({});
	const [savedReturnForm, setSavedReturnForm] = useState<ReturnFormType | null>(null);
	const [deltaReturnForm, setDeltaReturnForm] = useState<ReturnFormType | null>(null);

	const [warningDialogOpen, setWarningDialogOpen] = useState(false);
	const log = useLog();

	extendReturnForm(returnForm);
	(window as any).returnForm = returnForm;
	(window as any).order = order;

	// load from url
	useAsyncEffect(async () => {
		if (!match.params.formId || match.params.formId === returnForm.id) {
			return;
		}
		setLoading(true);

		try {
			const response = await axios.get<ReturnFormType>(`/api/orders/transports/${match.params.id}/return-forms/${match.params.formId}`);

			setSavedReturnForm(response.data);
			setReturnForm(response.data);

			if (match.params.deltaOrderId && match.params.deltaFormId) {
				const deltaResponse = await axios.get<ReturnFormType>(`/api/orders/transports/${match.params.deltaOrderId}/return-forms/${match.params.deltaFormId}`);

				setDeltaReturnForm(deltaResponse.data);
			}
		} catch {
			setSavedReturnForm(null);
			setReturnForm({});

			history.push('/orders/transports');
		} finally {
			setLoading(false);
		}
	}, [match.params.formId, match.params.deltaOrderId, match.params.deltaFormId]);

	// background save
	const [requestSave, saving] = useBackgroundApi(async (retryCount) => {
		if (returnForm === savedReturnForm) {
			// console.log("no changes occurred since last save");
			return;
		}

		try {
			if (returnForm.id && returnForm.orderId) {
				log.debug(`Updating return form ${returnForm.id}`);

				await axios.put(`/api/orders/transports/${returnForm.orderId}/return-forms/${returnForm.id}`, returnForm);

				log.info(`Return form ${returnForm.id} saved`);
			} else if (!!order) {
				log.debug(`Creating new return form in order ${order.id}`);

				const response = await axios.post(`/api/orders/transports/${order.id}/return-forms`, returnForm);
				const { id, orderId, title } = response.data;

				log.info(`New return in order ${order.id} created with id ${id}`);

				setReturnForm(f => ({
					...f,
					id,
					orderId,
					title
				}));

				if (!match.params.formId) {
					history.replace(`/orders/transports/${orderId}/return-forms/${id}`);
				}
			}

			setSavedReturnForm(returnForm);
		} catch (e) {
			setSavedReturnForm(null);
			throw e;
		}
	});

	// auto save after 5s of inactivity
	const [, startInactivity] = useTimeout(5000, {
		callback: () => requestSave()
	});

	useEffect(() => {
		if (!form.dirty || returnForm === savedReturnForm) {
			return;
		}

		// console.log("starting inactivity", returnForm, savedReturnForm);
		startInactivity();
	}, [returnForm]);

	const form = useForm({
		values: returnForm,
		setValues: setReturnForm,
		deltaValues: deltaReturnForm
	});

	const [activeStep, setActiveStep] = useState(0);
	const [validationFailed, setValidationFailed] = useAutoResetState(false, 1000);

	useEffect(() => {
		if (!returnForm.isLocked) {
			return;
		}

		const readOnly = returnForm.isLocked();
		if (form.readOnly !== readOnly) {
			form.setReadOnly(readOnly);
		}
	}, [form.readOnly, returnForm]);

	const gotoStep = useCallback((step: ((prevState: number) => number) | number) => {
		setActiveStep(step);
	}, []);

	const gotoPreviousStep = useCallback(() => {
		gotoStep(s => Math.max(0, s - 1));
	}, []);

	const gotoNextStep = useCallback(() => {
		const validated = form.validateAll();
		if (validated) {
			requestSave();
			gotoStep(s => Math.min(s + 1, steps.length - 1));
		} else {
			setValidationFailed(true);
		}
	}, [form.validateAll]);

	const gotoOrder = useCallback(() => {
		history.push(`/orders/transports/${order?.id}`);
	}, [order]);

	const complete = async () => {
		gotoOrder();
	};

	const stepperRef = useRef<any>();
	useLayoutEffect(() => {
		if (!stepperRef.current) {
			return;
		}

		const activeStepUi = stepperRef.current.querySelector(`#step-anchor-${activeStep}`);
		if (activeStepUi) {
			window.setTimeout(() => {
				activeStepUi.scrollIntoView({ behavior: 'smooth' });
			}, 50);
		}
	}, [activeStep]);

	const isFirstStep = activeStep === 0;
	const isLastStep = activeStep === steps.length - 1;

	const showWizard = returnForm.isSigned && !returnForm.isSigned() && !deltaReturnForm;

	const handleNextStep = () => {
		if (!isLastStep) {

			if (order?.status === "Cancelled") {
				setWarningDialogOpen(true);
			}

			gotoNextStep();
		} else
			complete();
	};

	const footer = order && !loading && returnForm && showWizard && (
		<>
			<Divider />
			<MobileStepper
				variant="progress"
				steps={steps.length}
				position="static"

				activeStep={activeStep}
				nextButton={
					<Button
						size="small"
						disabled={isLastStep && saving}
						onClick={handleNextStep}
						sx={[{
							transition: "color 200ms ease-out"
						},
							(validationFailed && { color: theme.palette.error.main })]}
					>
						{!isLastStep ? "Weiter" : "Fertig"}
						<KeyboardArrowRight />
					</Button>
				}
				backButton={
					< Button
						size="small"
						onClick={!isFirstStep ? gotoPreviousStep : gotoOrder}
						sx={{ transition: "color 200ms ease-out" }}
					>
						<KeyboardArrowLeft />
						{!isFirstStep ? "Zurück" : "Auftrag"}
					</Button>
				}
			/>
		</>
	);

	const [creatingDeltaForm, setCreatingDeltaForm] = useState(false);

	const createDeltaReturnForm = async () => {
		setCreatingDeltaForm(true);

		try {
			const { data: newReturnForm } = await axios.post(`/api/orders/transports/${order?.id}/return-forms`, { predecessorId: returnForm.id }, { params: { apply: false } });
			history.push(`/orders/transports/${order?.id}/return-forms/${newReturnForm.id}`);
		} finally {
			setCreatingDeltaForm(false);
		}
	};

	useEffect(() => {
		setWarningDialogOpen(order?.status === "Cancelled");
	}, [order]);

	return (
		<Layout
			title="Übergabeprotokoll"
			plateNumber={order?.plateNumber}
			navigation={<OrdersNavigation />}
			footer={footer}
			sidebar={order && <ReturnFormSidebar
				order={order}
				returnForm={returnForm}
				deltaFormId={match.params.deltaFormId}
				deltaReturnForm={deltaReturnForm}
				setDeltaReturnForm={setDeltaReturnForm}
			/>}
		>
			{order && <>
				<Box display="flex" justifyContent="space-between" alignItems="baseline" flexShrink={0}>
					<OrderHeader order={order} />
				</Box>
				<Box>
					{!loading && returnForm && (
						<Grid container spacing={4}>
							<Grid item xs={12} sm={12} md={9} lg={8} xl={6}>
								<Box width="100%">
									<Stepper
										ref={stepperRef}
										activeStep={activeStep}
										orientation="vertical"
										sx={{
											padding: 0,
											paddingTop: theme.spacing(2),
											background: 'none'
										}}
									>
										{steps.map((step, index) => (
											<Step
												key={index}
												completed={!showWizard ? false : activeStep > index}
												active={!showWizard ? true : activeStep === index}
											>
												<StepLabel
													onClick={() => gotoStep(index)}
												>
													<Box
														id={`step-anchor-${index}`}
														sx={{
															top: -theme.spacing(2),
															position: "relative"
														}}
													/>
													{step.title}
												</StepLabel>
												<StepContent
													sx={{
														paddingTop: theme.spacing(2)
													}}
													transitionDuration={0}
												>
													<Box mb={2}>
														<Grid container spacing={4}>
															{step.ui({
																returnForm,
																form,
																save: requestSave,
																order: order,
																unsavedChanges: returnForm !== savedReturnForm
															})}
														</Grid>
													</Box>
												</StepContent>
											</Step>
										))}
										{returnForm.isSigned && returnForm.isSigned() && !isDorfmarkAddress(order?.destinationAddress) && (
											<Step
												key={steps.length}
												completed={false}
												active
											>
												<StepLabel>
													Weitere Schritte
												</StepLabel>
												<StepContent
													sx={{
														paddingTop: theme.spacing(2)
													}}
													transitionDuration={0}
												>
													<Box mb={2}>
														<Button
															size="medium"
															color="primary"
															disabled={creatingDeltaForm}
															onClick={createDeltaReturnForm}
														>
															Differenzprotokoll erstellen
														</Button>
													</Box>
												</StepContent>
											</Step>
										)}
									</Stepper>
								</Box>
							</Grid>
						</Grid>
					)}
				</Box>
				<Dialog
					onClose={(event, reason) => {
						if (reason === "backdropClick") return false;
					}}
					disableEscapeKeyDown
					maxWidth="xs"
					open={warningDialogOpen}
				>
					<DialogTitle style={{ backgroundColor: statusSettings[order.status].backgroundColor, textAlign: "center" }}>
						<Box p={1}>Warnung</Box>
					</DialogTitle>
					<DialogContent dividers>
						<Box>
							Sie erstellen ein Protokoll in einem zurückgegeben Auftrag. Bitte wenden Sie sich an Ihren Ansprechpartner.
						</Box>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => history.goBack()} color="primary">
							Abbrechen
						</Button>
						<Button onClick={() => setWarningDialogOpen(false)} color="primary">
							Ok
						</Button>
					</DialogActions>
				</Dialog>
				<Prompt
					when={!!savedReturnForm && returnForm !== savedReturnForm}
					message={`Die Änderungen an dem Rücknahmeprotokoll wurden noch nicht gespeichert, beim Verlassen der Seite gehen die Änderungen verloren.`}
				/>
			</>}
		</Layout>
	);
}

export default ReturnForm;

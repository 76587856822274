import FormDamages from "../../../../Components/FormDamages";
import { damageComponents, damageTypes } from "../../../../system/damages";
import { Form } from "../../../../system/useForm";

const DAP06RemarksExterior = ({ form }: { form: Form }) => {
	return (
		<FormDamages
			name="remarks.toExteriors"
			form={form}
			damageTypes={damageTypes.exterior}
			damageComponents={damageComponents.exterior}
		/>
	);
};

export default DAP06RemarksExterior;

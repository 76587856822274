import { useCallback } from "react";
import { Form } from "../../../../system/useForm";
import Grid from "@mui/material/Grid";
import FormText from "Components/FormText";
import { Accordion, AccordionSummary, alpha, Button, IconButton, useTheme } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";

const DAP07RemarksOther = ({ form, stepsProps }: { form: Form, stepsProps: any }) => {
	const formfieldName = "remarks.othersTechnical";
	const remarks: string[] = form.getValue(formfieldName) || [];
	const theme = useTheme();

	const add = useCallback(() => {
		const newRemarks: string[] = [...remarks, ""];

		form.setValue(formfieldName, newRemarks, true);
	}, [remarks]);

	const remove = useCallback((i) => {
		remarks.splice(i, 1);

		form.setValue(formfieldName, remarks, true);
	}, [remarks]);

	return (
		<Grid item xs={9}>
			{remarks.map((r, i) => (
				<Grid item key={i}>
					<Grid container display="flex" flexDirection="column">
						<Grid item xs={12}>
							<FormText
								name={`${formfieldName}[${i}]`}
								label={`#${i + 1}`}
								form={form}
								rows={2}
								multiline
								fullWidth
								options={{
									required: true
								}}
							/>
						</Grid>
						{!form.readOnly && (
							<Grid alignSelf="flex-end">
								<IconButton color="secondary" onClick={() => remove(i)}>
									<Delete />
								</IconButton>
							</Grid>
						)}
					</Grid>
				</Grid>

			))}
			{!form.readOnly && (
				<Accordion expanded={false} onChange={add}>
					<AccordionSummary
						sx={{
							color: alpha(theme.palette.secondary.main, 0.5),
							"&:hover": {
								color: theme.palette.secondary.main
							},
							marginTop: theme.spacing(1),
							alignItems: "center"
						}}
					>
						<Button
							size="small"
							color="primary"
							startIcon={<Add />}
						>
							Neuen Eintrag hinzufügen
						</Button>
					</AccordionSummary>
				</Accordion>
			)}
		</Grid>
	);
};

export default DAP07RemarksOther;

import { isDefined, isValidBoolean, isValidNumber, isValidText } from '../../../../system/validators';
import { ShipmentForm, ShipmentFormStatus } from '../../../../system/Domain';

export default (r: ShipmentForm) => !r ? r : Object.assign(r, {
	isReadonly: () => r.status == ShipmentFormStatus.closed || r.status == ShipmentFormStatus.invalidated,
	isSigned: () => isDefined(r.pickupDetails)
		&& isDefined(r.pickupDetails?.pickupPerson?.signature)
		&& isValidText(r.pickupDetails?.pickupPerson?.signature?.image)
		&& isDefined(r.pickupDetails?.senderSignature)
		&& isValidText(r.pickupDetails?.senderSignature?.image),

	isValid: () =>
		isDefined(r.conditionAndEquipment)
		&& isValidBoolean(r.conditionAndEquipment.correctVin)
		&& isValidBoolean(r.conditionAndEquipment.inspectionReports)
		&& isValidNumber(r.conditionAndEquipment.mileage)
		&& isValidBoolean(r.conditionAndEquipment.serviceBooklet)
		&& isValidBoolean(r.conditionAndEquipment.serviceRecord)
		&& isValidBoolean(r.conditionAndEquipment.radio)
		&& isValidBoolean(r.conditionAndEquipment.navigationDevice)
		&& isValidBoolean(r.conditionAndEquipment.navigationDataMedium)

		&& isDefined(r.installedTiresAndRims)
		&& isValidText(r.installedTiresAndRims?.tireType)
		&& isValidText(r.installedTiresAndRims?.rimType)

		&& isValidBoolean(r.additionalTiresAndRims)
		&& (!r.additionalTiresAndRims || (
			isValidText(r.suppliedTiresAndRims?.tireType)
			&& isValidText(r.suppliedTiresAndRims?.rimType)
		))
		&& isDefined(r.keys)
		&& isValidNumber(r.keys.vehicleKeys)
		&& isValidNumber(r.keys.workshopKeys)
		&& isValidNumber(r.keys.remoteControls)
		&& isDefined(r.accessories)
		&& isDefined(r.accessories.trailerHitch)
		&& isValidText(r.accessories.trailerHitch.hitch)
		&& ((r.accessories.trailerHitch.hitch == "None") || isValidNumber(r.accessories.trailerHitch.keys))
		&& isValidBoolean(r.accessories.loadSecuringNet)
		&& isValidBoolean(r.accessories.bootCover)
		&& isValidBoolean(r.accessories.isChargingCable)
		&& (!r.accessories.isChargingCable || isValidNumber(r.accessories.chargingCables))

		&& isDefined(r.pickupDetails)
		&& isValidText(r.pickupDetails.type)
		&& ((r.pickupDetails.type != "Logistic") || isValidText(r.pickupDetails.lkwPlateNumber))
		&& isDefined(r.pickupDetails.pickupPerson)
		&& isValidText(r.pickupDetails.pickupPerson.firstName)
		&& isValidText(r.pickupDetails.pickupPerson.lastName)
		&& isValidText(r.pickupDetails.pickupPerson.street)
		&& isValidText(r.pickupDetails.pickupPerson.zipCode)
		&& isValidText(r.pickupDetails.pickupPerson.city)
		&& isValidText(r.pickupDetails.pickupPerson.nationality)
		&& isDefined(r.pickupDetails.pickupPerson.idCard)
		&& isValidText(r.pickupDetails.pickupPerson.idCard.type)
		&& isValidText(r.pickupDetails.pickupPerson.idCard.number)
		&& isValidText(r.pickupDetails.pickupPerson.idCard.issuer)
		&& isValidText(r.pickupDetails.pickupPerson.idCard.validTill)
		&& isDefined(r.pickupDetails.pickupPerson.signature) && isValidText(r.pickupDetails.pickupPerson.signature.image)
		&& isDefined(r.pickupDetails.senderInfo)
		&& isValidText(r.pickupDetails.senderInfo.firstName)
		&& isValidText(r.pickupDetails.senderInfo.lastName)
		&& isDefined(r.pickupDetails.senderSignature) && isValidText(r.pickupDetails.senderSignature.image)
});

import moment from "moment";
import { useEffect } from 'react';
import { Form } from "../system/useForm";
import { DatePicker, DateTimePicker, DateView, MobileTimePicker } from "@mui/x-date-pickers";
import { IconButton, InputAdornment } from "@mui/material";
import { AccessTime } from "@mui/icons-material";

interface Props {
	name: string
	form: Form
	label: string
	openTo?: any //DatePickerView
	views?: any //DatePickerView[]
	variant: 'date' | 'datetime' | 'time'
	margin?: any
	format?: string
	ampm?: boolean
	required?: boolean
	disablePast?: boolean
	disableFuture?: boolean
	disabled?: boolean
	fullWidth?: boolean
	disableWeekends?: boolean
	minDate?: string
	maxDate?: string
	disabledKeyboard?: boolean
	useTimeIcon?: boolean
}

export const disableWeekends = (date: any) => date?.get('d') === 0 || date?.get('d') === 6 || false;
const FormDateTimePicker = (props: Props) => {
	useEffect(() => {
		props.form.register(props.name, { required: props.required ?? false });
		return () => {
			props.form.unregister(props.name);
		};
	}, [props.name]);

	const handleDateChange = (d: any) => props.form.setValue(props.name, (moment(d) || moment()).startOf("d").toISOString(true));
	const handleTimeChange = (d: any) => props.form.setValue(props.name, (moment(d) || moment()).toISOString(true));
	const handleDateTimeChange = (d: any) => props.form.setValue(props.name, (moment(d) || moment()).toISOString(true));

	const value = props.form.getValue(props.name);
	if (props.variant === 'date') {
		return (
			<DatePicker
				label={props.label}
				format={props.format}
				openTo={props.openTo as DateView ?? "year"}
				views={props.views ?? ["day"]}
				value={value ? moment(value) : null}
				onChange={handleDateChange}
				disabled={props.disabled ?? props.form.readOnly}
				disablePast={props.disablePast ?? undefined}
				disableFuture={props.disableFuture ?? undefined}
				minDate={props.minDate ? moment(props.minDate) : undefined}
				maxDate={props.maxDate ? moment(props.maxDate) : undefined}
				slotProps={{
					textField: {
						variant: 'standard', error: !!props.form.errors[props.name], ...(props.disabledKeyboard && ({
							disabled: true, sx: {
								"& input.Mui-disabled": {
									WebkitTextFillColor: "white"
								}
							}
						}))
					}
				}}
				shouldDisableDate={props.disableWeekends ? disableWeekends : undefined}
				sx={{
					width: (props.fullWidth ? "100%" : null),
					margin: props.margin ?? "none"
				}}
			/>
		);
	} else if (props.variant === 'datetime') {
		return (
			<DateTimePicker
				label={props.label}
				format={props.format ?? "DD.MM.YYYY HH:ss"}
				value={value ? moment(value) : null}
				onChange={handleDateTimeChange}
				disablePast={props.disablePast ?? undefined}
				disableFuture={props.disableFuture ?? undefined}
				disabled={props.disabled ?? props.form.readOnly}
				ampm={props.ampm ?? false}
				maxDate={props.maxDate ? moment(props.maxDate) : undefined}
				slotProps={{
					textField: {
						variant: 'standard', error: !!props.form.errors[props.name], ...(props.disabledKeyboard && ({
							disabled: true, sx: {
								"& input.Mui-disabled": {
									WebkitTextFillColor: "white"
								}
							}
						}))
					}
				}}
				shouldDisableDate={props.disableWeekends ? disableWeekends : undefined}
				sx={{
					width: (props.fullWidth ? "100%" : null),
					margin: props.margin ?? "none"
				}}
			/>
		);
	} else {
		return (
			<MobileTimePicker
				ampm={props.ampm ?? false}
				label={props.label}
				value={value ? moment(value) : null}
				disabled={props.disabled ?? props.form.readOnly}
				onChange={handleTimeChange}
				slotProps={{
					textField: {
						variant: 'standard', error: !!props.form.errors[props.name], ...(props.disabledKeyboard && ({
							disabled: true, sx: {
								"& input.Mui-disabled": {
									WebkitTextFillColor: "white"
								}
							}
						})),
						InputProps: {
							endAdornment: props.useTimeIcon === true && (
								<InputAdornment position="end">
									<IconButton sx={{ marginRight: "-12px" }}>
										<AccessTime />
									</IconButton>
								</InputAdornment>
							)
						}
					}
				}}
				sx={{
					width: (props.fullWidth ? "100%" : null),
					margin: props.margin ?? "none"
				}}
			/>
		);
	}
};

export default FormDateTimePicker;

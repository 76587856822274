import { atom } from "recoil";
import { PickupDetails } from "system/Domain";
import localStorageEffect from "./effects/localStorageEffect";

const pickupAtom = atom<PickupDetails>({
	key: "pickup",
	default: null,
	effects: [localStorageEffect("pickup", null)],
});

export { pickupAtom };

import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import dot from "dot-object";

export interface UseFormProps {
	values: any,
	setValues: ((values: any) => void) | Dispatch<SetStateAction<any>>
	deltaValues?: any
}

export interface FormOptions {
	required: boolean,
	email?: boolean
}

export interface Form {
	register: (name: string, options?: FormOptions) => void
	unregister: (name: string) => void
	validate: (name: string, value: any) => boolean,
	validateAll: () => boolean,
	getValue: (name: string) => any
	getDeltaValue: (name: string) => any,
	setValue: (name: string, value: any, triggerValidation?: boolean) => void
	deltaMode: boolean
	readOnly: boolean
	setReadOnly: (readOnly: boolean) => void
	dirty: boolean
	errors: any
	setErrors: (errors: any) => void
	hasErrors: boolean
	formId:string // for debug purposes mostly
}

export const validateEmail = (email: string) => {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};

export default ({ values, setValues, deltaValues = null }: UseFormProps) => {
	const [fields, setFields] = useState<any>({});
	const [errors, setErrors] = useState<any>({});
	const [readOnly, setReadOnly] = useState(false);
	const [dirty, setDirty] = useState(false);
	const [formId, ] = useState(performance.now().toString() );

	useEffect(() => {
		if (Object.keys(fields).length === 0) {
			return;
		}
	}, [fields]);

	const register = useCallback((name: string, options?: FormOptions) => {
		setFields((fields: any) => ({
			...fields,
			[name]: options || {}
		}));
	}, []);

	const unregister = useCallback((name: string) => {
		setFields((fields: any) => {
			const { [name]: currentField, ...newFields } = fields;
			return newFields;
		});
		setErrors((errors: any) => {
			const { [name]: currentError, ...newErrors } = errors;
			return newErrors;
		});
	}, []);



	const validate = useCallback((name, value) => {
		const options = fields[name];
		if (!options) {
			return false;
		}

		const error: any = {};

		if (options.required && (value === undefined || value === null || String(value).trim() === "")) {
			error.required = true;
		}

		if (options.email && value) {
			const emailValidationSuccess = validateEmail(value);

			if (!emailValidationSuccess) {
				error.email = true;
			}
		}

		if (Object.keys(error).length === 0) {
			if (errors[name] !== undefined) {
				setErrors((errors: any) => {
					const { [name]: currentError, ...newErrors } = errors;
					return newErrors;
				});
			}

			return true;
		}

		setErrors((errors: any) => ({
			...errors,
			[name]: error
		}));

		return false;
	}, [fields, errors]);

	const getValue = useCallback((name: string) => {
		try {
			return dot.pick(name, values);
		} catch {
			return undefined;
		}
	}, [values]);

	const getDeltaValue = useCallback((name: string) => {
		try {
			return dot.pick(name, deltaValues);
		} catch {
			return undefined;
		}
	}, [deltaValues]);

	const validateAll = useCallback(() => {
		const names = Object.keys(fields);
		let validated = true;
		for (const name of names) {
			validated = validate(name, getValue(name)) && validated;
		}

		return validated;
	}, [fields, getValue]);

	const setValue = useCallback((name: string, value: any, triggerValidation?: boolean) => {
		setValues((values: any) => {
			const newValues = { ...values };
			dot.set(name, value, newValues);
			return newValues;
		});

		if (!dirty) {
			setDirty(true);
		}

		if (triggerValidation) {
			validate(name, value);
		}
	}, [validate]);

	const form: Form = {
		register,
		unregister,
		validate,
		validateAll,
		getValue,
		getDeltaValue,
		setValue,
		errors,
		setErrors,
		readOnly,
		setReadOnly,
		dirty,
		deltaMode: !!deltaValues,
		hasErrors: Object.keys(errors).length > 0,
		formId
	};

	return form;
};

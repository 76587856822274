/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";
import Loading from "Components/Loading";
import Login from "Login";
// @ts-ignore
import logRemote from "loglevel-plugin-remote";
import TransportPricingPageNew from "Pages/Admin/TransportPricing/TransportPricingPageNew";
import CustomerFAQ from "Pages/Customer/CustomerFAQ";
import NetworkOverview from "Pages/Network/NetworkOverview";
import OrdersDashboardLayout from "Pages/Orders/Dashboard/OrdersDashboardLayout";
import ReturnOrderSessionPage from "Pages/Orders/Returns/Sessions/ReturnOrderSessionPage";
import AddNewPoolFleetContractPage from "Pages/PoolFleet/AddNewPoolFleetContractPage";
import NewPricingRequest from "Pages/PricingRequests/NewPricingRequest";
import PricingRequestLayout from "Pages/PricingRequests/PricingRequestLayout";
import PricingRequestsLayout from "Pages/PricingRequests/PricingRequestsLayout";
import UserSettingsLayout from "Pages/Users/UserSettingsLayout";
import AssessmentPage from "Pages/Vehicles/Assessments/AssessmentPage";
import NewAssessmentPage from "Pages/Vehicles/Assessments/NewAssessmentPage";
import DocumentArrivals from "Pages/Vehicles/DocumentManagement/DocumentArrivals";
import LeaseplanImport from "Pages/Vehicles/LeaseplanImport";
import VehicleAllDocuments from "Pages/Vehicles/VehicleAllDocuments";
import VehicleDashboard from "Pages/Vehicles/VehicleDashboard";
import VehiclesLegacyDashboard from "Pages/Vehicles/VehiclesLegacyDashboard";
import SalesDashboardLayout from "Pages/VehicleSales/SalesDashboardLayout";
import { Suspense, useEffect } from "react";
import { Route } from "react-router";
import { Redirect, Switch } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { logoutAtom } from "system/atoms/logout";
import { menuAtom } from "system/atoms/menu";
import { navigationAtom } from "system/atoms/navigation";
import { settingsAtom } from "system/atoms/settings";
import auth0 from "system/auth0";
import Help from "./Components/Layout/Help";
import PrivateRoute from "./Components/PrivateRoute";
import CustomerSettingsOverview from "./Pages/Admin/CustomerSettings/CustomerSettingsOverview";
import FtpStoragePage from "./Pages/Admin/FtpStoragePage";
import Job from "./Pages/Admin/Jobs/JobLayout";
import Jobs from "./Pages/Admin/Jobs/Jobs";
import Postman from "./Pages/Admin/Postman";
import RealtimeLog from "./Pages/Admin/RealtimeLog";
import SecurityDocumentation from "./Pages/Admin/SecurityDocumentation";
import TransportPricingPage from "./Pages/Admin/TransportPricing/TransportPricingPage";
import TransportPricingsPage from "./Pages/Admin/TransportPricing/TransportPricingsPage";
import DadArrivalTransmissionsLayout from "./Pages/ArrivalTransmissions/DadArrivalTransmissionsLayout";
import VehicleAssessmentsPage from "./Pages/Assessments/VehicleAssessmentsPage";
import AversiInsuranceCompaniesPage from "./Pages/Aversi/AversiInsuranceCompaniesPage";
import AversiLinesPage from "./Pages/Aversi/AversiLinesPage";
import AversiPaymentPlanPage from "./Pages/Aversi/AversiPaymentPlanPage";
import AversiSessionDetailsPage from "./Pages/Aversi/AversiSessionDetailsPage";
import AversiSessionPage from "./Pages/Aversi/AversiSessionPage";
import AversiSessionsPage from "./Pages/Aversi/AversiSessionsPage";
import BillingOverview from "./Pages/Billings/BillingOverview";
import Billings from "./Pages/Billings/Billings";
import BillingServices from "./Pages/Billings/BillingServices";
import NewBilling from "./Pages/Billings/NewBilling";
import Companies from "./Pages/Companies/Companies";
import Dekra from "./Pages/Companies/Dekra";
import StatisticPage from "./Pages/Companies/Statistics/StatisticPage";
import CustomerPage from "./Pages/Customer/CustomerPage";
import ImportPage from "./Pages/Imports/ImportPage";
import Index from "./Pages/Index";
import Inventories from "./Pages/Inventories/Inventories";
import InventoryVehicles from "./Pages/Inventories/Inventory";
import InventoryScan from "./Pages/Inventories/InventoryScan";
import InvoicePage from "./Pages/Invoices/InvoicePage";
import InvoicesPage from "./Pages/Invoices/Invoices";
import Notifications from "./Pages/Notifications/Notifications";
import AssessmentOrderDetails from "./Pages/Orders/Assessments/AssessmentOrderDetails";
import AssessmentOrdersEdit from "./Pages/Orders/Assessments/AssessmentOrdersEdit";
import AssessmentOrdersChange from "./Pages/Orders/Assessments/AssessmentOrdersChange";
import AssessmentOrdersNew from "./Pages/Orders/Assessments/AssessmentOrdersNew";
import AssessmentOrdersOverview from "./Pages/Orders/Assessments/AssessmentOrdersOverview";
import CustomerOrderDetails from "./Pages/Orders/Customers/CustomerOrderDetails";
import CustomerOrdersNew from "./Pages/Orders/Customers/CustomerOrdersNew";
import CustomerOrdersOverview from "./Pages/Orders/Customers/CustomerOrdersOverview";
import OrderImportsPage from "./Pages/Orders/OrderImportsPage";
import ReturnOrderDetails from "./Pages/Orders/Returns/ReturnOrderDetails";
import ReturnOrdersDashboard from "./Pages/Orders/Returns/ReturnOrdersDashboard";
import ReturnOrdersEdit from "./Pages/Orders/Returns/ReturnOrdersEdit";
import ReturnOrderSessionsOverview from "./Pages/Orders/Returns/ReturnOrderSessionsOverview";
import ReturnOrdersNew from "./Pages/Orders/Returns/ReturnOrdersNew";
import NewTransportOrder from "./Pages/Orders/Transports/New/NewTransportOrder";
import ReturnForm from "./Pages/Orders/Transports/ReturnForms/ReturnForm";
import TransportOrders from "./Pages/Orders/Transports/TransportOrders";
import TransportOrderScan from "./Pages/Orders/Transports/TransportOrderScan";
import TransportOrderView from "./Pages/Orders/Transports/TransportOrderView";
import UnregistrationOrderDetails from "./Pages/Orders/Unregistrations/UnregistrationOrderDetails";
import UnregistrationOrderImports from "./Pages/Orders/Unregistrations/UnregistrationOrderImports";
import UnregistrationOrdersEdit from "./Pages/Orders/Unregistrations/UnregistrationOrdersEdit";
import UnregistrationOrdersNew from "./Pages/Orders/Unregistrations/UnregistrationOrdersNew";
import UnregistrationOrdersOverview from "./Pages/Orders/Unregistrations/UnregistrationOrdersOverview";
import PoolFleetVehiclePage from "./Pages/PoolFleet/PoolFleetVehiclePage";
import PoolFleetVehiclesPage from "./Pages/PoolFleet/PoolFleetVehiclesPage";
import ApiUser from "./Pages/Users/ApiUser";
import UsersPage from "./Pages/Users/UsersPage";
import ArmsVehicle from "./Pages/Vehicles/Arms/ArmsVehicle";
import AssessmentsPage from "./Pages/Vehicles/Assessments/AssessmentsPage";
import BaseDataLayout from "./Pages/Vehicles/BaseDataLayout";
import DekraAssessmentsPage from "./Pages/Vehicles/DekraAssessments/DekraAssessmentsPage";
import ManualVehicleDocumentArrival from "./Pages/Vehicles/DocumentManagement/ManualVehicleDocumentArrival";
import VehicleDocumentManagementDashboard from "./Pages/Vehicles/DocumentManagement/VehicleDocumentManagementDashboard";
import VehicleRegistrationDocumentArrivalsImports from "./Pages/Vehicles/DocumentManagement/VehicleRegistrationDocumentArrivalsImports";
import VehicleRegistrationDocumentHandover from "./Pages/Vehicles/DocumentManagement/VehicleRegistrationDocumentHandover";
import VehicleRegistrationDocumentHandoverImports from "./Pages/Vehicles/DocumentManagement/VehicleRegistrationDocumentHandoverImports";
import VehicleRegistrationDocumentRequestsImports from "./Pages/Vehicles/DocumentManagement/VehicleRegistrationDocumentRequestsImports";
import VehicleRegistrationDocumentUnregistrationImports from "./Pages/Vehicles/DocumentManagement/VehicleRegistrationDocumentUnregistrationImports";
import Lots from "./Pages/Vehicles/Lots";
import NewVehicle from "./Pages/Vehicles/NewVehicle";
import VehiclePictures from "./Pages/Vehicles/PictureSets";
import NewVehiclePictureSet from "./Pages/Vehicles/PictureSets/NewVehiclePictureSet";
import VehiclePictureSets from "./Pages/Vehicles/PictureSets/VehiclePictureSets";
import RepairOrdersLayout from "./Pages/Vehicles/RepairOrdersLayout";
import AfterShippingVehiclePage from "./Pages/Vehicles/Shipping/AfterShippingVehiclePage";
import ShipVehiclePage from "./Pages/Vehicles/Shipping/ShipVehiclePage";
import VehicleHistory from "./Pages/Vehicles/VehicleHistory";
import VehicleOrders from "./Pages/Vehicles/VehicleOrders";
import VehicleOverview from "./Pages/Vehicles/VehicleOverview";
import VehicleProcess from "./Pages/Vehicles/VehicleProcess";
import VehicleRepairOrders from "./Pages/Vehicles/VehicleRepairOrders";
import Vehicles from "./Pages/Vehicles/Vehicles";
import VehicleScan from "./Pages/Vehicles/VehicleScan";
import CarmarketOverview from "./Pages/VehicleSales/Carmarket/CarmarketOverview";
import ConfirmPaymentPage from "./Pages/VehicleSales/Orders/ConfirmPaymentPage";
import CreateSalesOrderPage from "./Pages/VehicleSales/Orders/CreateSalesOrderPage";
import DirectSalesPage from "./Pages/VehicleSales/Orders/DirectSalesPage";
import ManualSalesOrderPage from "./Pages/VehicleSales/Orders/ManualSalesOrderPage";
import SaleOrderEmailPage from "./Pages/VehicleSales/Orders/SaleOrderEmailPage";
import SalesOrderPage from "./Pages/VehicleSales/Orders/SalesOrderPage";
import SalesOrdersPage from "./Pages/VehicleSales/Orders/SalesOrdersPage";
import SendDocumentsPage from "./Pages/VehicleSales/Orders/SendDocumentsPage";
import PricingTagsLayout from "./Pages/VehicleSales/PricingTagsLayout";
import SaleLayout from "./Pages/VehicleSales/SaleLayout";
import SalesLayout from "./Pages/VehicleSales/SalesLayout";
import VehicleServiceDetails from "./Pages/VehicleServices/VehicleServiceDetails";
import VehicleServiceImportsPage from "./Pages/VehicleServices/VehicleServiceImportsPage";
import VehicleServices from "./Pages/VehicleServices/VehicleServices";
import VehicleServicesForm from "./Pages/VehicleServices/VehicleServicesForm";
import VehicleServicesFormScan from "./Pages/VehicleServices/VehicleServicesFormScan";
import VehicleServicesOverview from "./Pages/VehicleServices/VehicleServicesOverview";
import VehicleServicesCloseSelectType from "./Pages/VehicleServices/VehicleServicesSelectType";
import VehicleServicesSelectTypeScan from "./Pages/VehicleServices/VehicleServicesSelectTypeScan";
import NewWorkSession from "./Pages/WorkSessions/NewWorkSession";
import WorkSessionNavigation from "./Pages/WorkSessions/WorkSessionNavigation";
import WorkSessions from "./Pages/WorkSessions/WorkSessions";
import { UserRole, UserSettings } from "./system/Domain";
import useAsyncEffect from "./system/useAsyncEffect";
import useEvents from "./system/useEvents";
import useLog from "./system/useLog";
import VehicleArrivalFormInput from "./Pages/Vehicles/Arrivals/VehicleArrivalFormInput";
import VehicleArrivalFormOutput from "Pages/Vehicles/Arrivals/VehicleArrivalFormOutput";
import VehicleArrivalsOverview from "Pages/Vehicles/Arrivals/VehicleArrivalsOverview";
import ShipmentFormPage from "Pages/Vehicles/Shipping/DAP/ShipmentFormPage";
import useUser from "./system/useUser";
import CompoundDesigner from "Components/CompoundDesigner/CompoundDesigner";
import FileDownloadLayout from "Pages/Files/FileDownloadLayout";
import BcaLayout from "Pages/VehicleSales/Bca/BcaLayout";
import LeasingLayout from "Pages/Vehicles/LeasingLayout";
import RemarketingLayout from "./Pages/Vehicles/RemarketingLayout";
import LeasingCustomersLayout from "Pages/Leasing/LeasingCustomersLayout";
import LeasingCustomerLayout from "Pages/Leasing/LeasingCustomerLayout";

export default () => {
	const log = useLog();

	useEffect(() => {
		log.info("Application loaded");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [settings, setSettings] = useRecoilState(settingsAtom);
	const [menu, setMenu] = useRecoilState(menuAtom);
	const [navigation, setNavigation] = useRecoilState(navigationAtom);

	useEffect(() => {
		setMenu((v) => ({ ...v, open: settings.menuOpen }));
		setNavigation((v) => ({ ...v, orientation: settings.navigationOrientation }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [settings]);

	const [user, setUser, hasRole, , hasAnyRole] = useUser();

	const logout = useRecoilValue(logoutAtom);
	useAsyncEffect(async () => {
		if (!auth0 || !logout) {
			return;
		}

		var client = await auth0.createClient();

		setUser(null);
		await client.logout({
			returnTo: window.location.origin,
		});
	}, [auth0, logout]);

	useAsyncEffect(async () => {
		if (!user?.loginToken || hasAnyRole([UserRole.Customer, UserRole.Driver])) {
			return;
		}

		const { data: settings } = await axios.get<UserSettings>(`/api/users/me/settings`);
		setSettings((s) => ({ ...s, ...settings }));
	}, [user?.loginToken]);

	useEffect(() => {
		if (!user || !user.loginToken) {
			return;
		}

		logRemote.setToken(user.loginToken.token);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user?.loginToken?.token]);

	const events = useEvents();

	useAsyncEffect(
		async () => {
			if (!user || !user.loginToken) {
				console.log("Not authenticated, no event source will be created");
				return;
			}

			if (!hasRole(UserRole.AldUser)) {
				console.log("Events are currently only supported for AldUsers");
				return;
			}

			console.log("Connecting to events");
			console.log(user);
			const { data: login } = await axios.post(`/api/events/login`);

			await events.connect(login.url, login.hub, login.token);
		},
		[user?.loginToken?.token],
		() => events.disconnect(),
	);

	const serviceRoles = [
		UserRole.ServiceProvider,
		UserRole.ServiceAccounting,
		UserRole.ServiceUser,
		UserRole.ServiceManager,
	];

	return (
		<Suspense fallback={<Loading />}>
			{!user?.loginToken && <Login />}
			{!!user?.loginToken && (
				<>
					<Switch>
						<PrivateRoute path="/notifications/:folder" component={Notifications} />
						<PrivateRoute path="/network" component={NetworkOverview} />

						<PrivateRoute path="/users/api-user" component={ApiUser} />
						<PrivateRoute path="/users" component={UsersPage} />

						<PrivateRoute
							path="/aversi/sessions/:id/details/:itemId"
							component={AversiSessionDetailsPage}
						/>
						<PrivateRoute path="/aversi/sessions/:id" component={AversiSessionPage} />
						<PrivateRoute path="/aversi/sessions" component={AversiSessionsPage} />
						<PrivateRoute path="/aversi/lines" component={AversiLinesPage} />
						<PrivateRoute path="/aversi/payment-plans" component={AversiPaymentPlanPage} />
						<PrivateRoute path="/aversi/insurance-companies" component={AversiInsuranceCompaniesPage} />

						<PrivateRoute path="/assessments/:vin" component={VehicleAssessmentsPage} />

						<PrivateRoute path="/orders/imports" component={OrderImportsPage} />

						<PrivateRoute path="/orders/customers/new" component={CustomerOrdersNew} />
						<PrivateRoute path="/orders/customers/faq" component={CustomerFAQ} />
						<PrivateRoute path="/orders/customers/form/:id" component={CustomerPage} />
						<PrivateRoute path="/orders/customers/:id" component={CustomerOrderDetails} />
						<PrivateRoute path="/orders/customers" component={CustomerOrdersOverview} />

						<PrivateRoute path="/orders/returns/sessions/:id" component={ReturnOrderSessionPage} />
						<PrivateRoute path="/orders/returns/sessions" component={ReturnOrderSessionsOverview} />

						<PrivateRoute path="/orders/returns/new" component={ReturnOrdersNew} />
						<PrivateRoute path="/orders/returns/:id/edit" component={ReturnOrdersEdit} />
						<PrivateRoute path="/orders/returns/:id" component={ReturnOrderDetails} />
						<PrivateRoute path="/orders/returns" component={ReturnOrdersDashboard} />

						<PrivateRoute path="/ftp-storage" component={FtpStoragePage} />

						<PrivateRoute
							path="/orders/assessments/new"
							component={AssessmentOrdersNew}
							roles={[UserRole.AldUser]}
						/>
						<PrivateRoute
							path="/orders/assessments/:id/edit"
							component={AssessmentOrdersEdit}
							roles={[UserRole.AldUser]}
						/>
						<PrivateRoute
							path="/orders/assessments/:id/change"
							component={AssessmentOrdersChange}
							roles={[UserRole.AldUser]}
						/>
						<PrivateRoute
							path="/orders/assessments/:id"
							component={AssessmentOrderDetails}
							roles={[UserRole.AldUser, UserRole.DamageAssessor]}
						/>
						<PrivateRoute
							path="/orders/assessments"
							component={AssessmentOrdersOverview}
							roles={[UserRole.AldUser, UserRole.DamageAssessor]}
						/>

						<PrivateRoute path="/orders/unregistrations/new" component={UnregistrationOrdersNew} />
						<PrivateRoute path="/orders/unregistrations/imports" component={UnregistrationOrderImports} />
						<PrivateRoute path="/orders/unregistrations/:id/edit" component={UnregistrationOrdersEdit} />
						<PrivateRoute path="/orders/unregistrations/:id" component={UnregistrationOrderDetails} />
						<PrivateRoute path="/orders/unregistrations" component={UnregistrationOrdersOverview} />

						<PrivateRoute path="/orders/transports/new" component={NewTransportOrder} />
						<PrivateRoute path="/orders/transports/scan" component={TransportOrderScan} />
						<PrivateRoute path="/orders/transports/:id/return-forms/new" component={ReturnForm} />
						<PrivateRoute
							path="/orders/transports/:id/return-forms/:formId/delta/:deltaOrderId/:deltaFormId"
							component={ReturnForm}
						/>
						<PrivateRoute path="/orders/transports/:id/return-forms/:formId" component={ReturnForm} />
						<PrivateRoute
							roles={[UserRole.AldUser, UserRole.LogisticCompany, UserRole.Driver, UserRole.LotManager]}
							path="/orders/transports/:id"
							component={TransportOrderView}
						/>
						<PrivateRoute
							roles={[UserRole.AldUser, UserRole.LogisticCompany, UserRole.Driver, UserRole.LotManager]}
							path="/orders/transports"
							component={TransportOrders}
						/>

						<PrivateRoute
							path="/orders/repairs"
							component={RepairOrdersLayout}
							roles={[UserRole.AldUser]}
						/>

						<PrivateRoute
							path="/orders/dashboard"
							component={OrdersDashboardLayout}
							roles={[UserRole.AldManager, UserRole.LotManager]}
						/>

						<PrivateRoute path="/orders/:id/return-forms/new" component={ReturnForm} />
						<PrivateRoute
							roles={[UserRole.AldUser, UserRole.LotManager, UserRole.LogisticCompany, UserRole.Driver]}
							path="/orders/:id/return-forms/:formId/delta/:deltaOrderId/:deltaFormId"
							component={ReturnForm}
						/>
						<PrivateRoute path="/orders/:id/return-forms/:formId" component={ReturnForm} />
						<PrivateRoute
							roles={[UserRole.AldUser, UserRole.LotManager, UserRole.LogisticCompany, UserRole.Driver]}
							path="/orders/:id"
							component={TransportOrderView}
						/>

						<PrivateRoute
							roles={[UserRole.AldUser, UserRole.LotManager, UserRole.LogisticCompany, UserRole.Driver]}
							path="/orders"
							component={TransportOrders}
						/>

						<PrivateRoute path="/invoices/:id" component={InvoicePage} roles={[UserRole.AldUser]} />
						<PrivateRoute path="/invoices" component={InvoicesPage} roles={[UserRole.AldUser]} />

						<PrivateRoute path="/imports/:id" component={ImportPage} />

						<PrivateRoute path="/admin/jobs/:id" component={Job} />
						<PrivateRoute path="/admin/jobs" component={Jobs} />
						<PrivateRoute
							path="/admin/documentation/security"
							component={SecurityDocumentation}
							roles={[UserRole.AldAdmin]}
						/>

						<PrivateRoute
							path="/admin/documentation/postman"
							component={Postman}
							roles={[UserRole.AldAdmin]}
						/>

						<PrivateRoute path="/realtime-log" component={RealtimeLog} />

						<PrivateRoute
							path="/companies/dekra"
							component={Dekra}
							companies={["Dekra", "ALD"]}
							roles={[UserRole.AldUser, UserRole.LotManager]}
						/>
						<PrivateRoute path="/companies" component={Companies} />

						<PrivateRoute path="/statistics/:date" component={StatisticPage} />
						<PrivateRoute
							path="/customer-settings"
							component={CustomerSettingsOverview}
							roles={[UserRole.AldUser]}
						/>
						<PrivateRoute
							path="/pool-fleet/vehicles/:id/contracts/new"
							component={AddNewPoolFleetContractPage}
							roles={[UserRole.AldUser]}
						/>
						<PrivateRoute
							path="/pool-fleet/vehicles/:id"
							component={PoolFleetVehiclePage}
							roles={[UserRole.AldUser]}
						/>
						<PrivateRoute
							path="/pool-fleet/vehicles"
							component={PoolFleetVehiclesPage}
							roles={[UserRole.AldUser]}
						/>

						<PrivateRoute
							path="/transport-pricing/:company/new"
							component={TransportPricingPageNew}
							roles={[UserRole.AldManager]}
						/>
						<PrivateRoute
							path="/transport-pricing/:company"
							component={TransportPricingPage}
							roles={[UserRole.AldManager]}
						/>
						<PrivateRoute
							path="/transport-pricing"
							component={TransportPricingsPage}
							roles={[UserRole.AldManager]}
						/>

						<PrivateRoute
							path="/vehicles/pictures/:vin"
							component={VehiclePictures}
							roles={[UserRole.AldUser, UserRole.LotManager]}
						/>

						<PrivateRoute
							path="/vehicles/services/close/select-type"
							component={VehicleServicesCloseSelectType}
							roles={serviceRoles}
						/>
						<PrivateRoute
							path="/vehicles/services/close/:id"
							component={VehicleServicesSelectTypeScan}
							roles={serviceRoles}
						/>
						<PrivateRoute
							path="/vehicles/services/new"
							component={VehicleServicesFormScan}
							roles={serviceRoles}
						/>
						<PrivateRoute
							path="/vehicles/services/imports"
							component={VehicleServiceImportsPage}
							roles={[UserRole.AldUser]}
						/>
						<PrivateRoute
							path="/vehicles/services"
							component={VehicleServicesOverview}
							roles={[UserRole.AldUser, UserRole.LotManager, ...serviceRoles]}
						/>

						<PrivateRoute
							path="/sales/dashboard"
							component={SalesDashboardLayout}
							roles={[UserRole.AldUser, UserRole.SalesUser]}
						/>
						<PrivateRoute path="/sales/search" component={SalesLayout} roles={[UserRole.SalesUser]} />
						<PrivateRoute
							path="/sales/pricing-requests/new"
							component={NewPricingRequest}
							roles={[UserRole.AldUser]}
						/>
						<PrivateRoute
							path="/sales/pricing-requests/:id"
							component={PricingRequestLayout}
							roles={[UserRole.AldUser]}
						/>
						<PrivateRoute
							path="/sales/pricing-requests"
							component={PricingRequestsLayout}
							roles={[UserRole.AldUser]}
						/>
						<PrivateRoute
							path="/sales/pricing-tags"
							component={PricingTagsLayout}
							roles={[UserRole.SalesUser]}
						/>

						<PrivateRoute
							path="/sales/carmarket"
							component={CarmarketOverview}
							roles={[UserRole.SalesUser]}
						/>

						<PrivateRoute path="/sales/bca" component={BcaLayout} roles={[UserRole.SalesUser]} />

						<PrivateRoute path="/sales/:id/shipment-form/:index" component={ShipmentFormPage} roles={[UserRole.LotManager]} />

						<PrivateRoute
							path="/sales/:id/confirm-payment"
							component={ConfirmPaymentPage}
							roles={[UserRole.SalesManager]}
						/>
						<PrivateRoute
							path="/sales/:id/direct-sales"
							component={DirectSalesPage}
							roles={[UserRole.SalesUser]}
						/>
						<PrivateRoute
							path="/sales/:id/manual-sales"
							component={ManualSalesOrderPage}
							roles={[UserRole.SalesUser]}
						/>
						<PrivateRoute
							path="/sales/:id/orders/:orderId/emails/:messageId"
							component={SaleOrderEmailPage}
							roles={[UserRole.SalesUser]}
						/>
						<PrivateRoute
							path="/sales/:id/orders/:orderId/send-documents"
							component={SendDocumentsPage}
							roles={[UserRole.SalesUser]}
						/>
						<PrivateRoute
							path="/sales/:id/orders/:orderId"
							component={SalesOrderPage}
							roles={[UserRole.SalesUser]}
						/>
						<PrivateRoute
							path="/sales/:id/orders"
							component={SalesOrdersPage}
							roles={[UserRole.SalesUser]}
						/>
						<PrivateRoute
							path="/sales/:id/order"
							component={CreateSalesOrderPage}
							roles={[UserRole.SalesUser]}
						/>
						<PrivateRoute path="/sales/:id" component={SaleLayout} roles={[UserRole.SalesUser]} />

						<PrivateRoute path="/sales" component={SalesLayout} roles={[UserRole.AldUser]} />

						<PrivateRoute path="/vehicles/new" component={NewVehicle} roles={[UserRole.AldUser]} />
						<PrivateRoute
							path="/vehicles/leaseplan-import"
							component={LeaseplanImport}
							roles={[UserRole.AldUser]}
						/>
						<PrivateRoute
							path="/vehicles/dashboard"
							component={VehiclesLegacyDashboard}
							roles={[UserRole.AldUser, UserRole.LotManager]}
						/>
						<PrivateRoute path="/vehicles/ship" component={ShipVehiclePage} roles={[UserRole.LotManager]} />
						<PrivateRoute
							path="/vehicles/registration-documents/dashboard"
							component={VehicleDocumentManagementDashboard}
							roles={[UserRole.AldUser]}
						/>
						<PrivateRoute
							path="/vehicles/registration-documents/imports/requests"
							component={VehicleRegistrationDocumentRequestsImports}
							roles={[UserRole.AldUser]}
						/>
						<PrivateRoute
							path="/vehicles/registration-documents/imports/unregistrations"
							component={VehicleRegistrationDocumentUnregistrationImports}
							roles={[UserRole.AldUser]}
						/>
						<PrivateRoute
							path="/vehicles/registration-documents/imports/arrivals"
							component={VehicleRegistrationDocumentArrivalsImports}
							roles={[UserRole.AldUser]}
						/>
						<PrivateRoute
							path="/vehicles/registration-documents/imports/handovers"
							component={VehicleRegistrationDocumentHandoverImports}
							roles={[UserRole.AldUser]}
						/>
						<PrivateRoute
							path="/vehicles/registration-documents/manual/arrivals"
							component={ManualVehicleDocumentArrival}
							roles={[UserRole.AldUser]}
						/>
						<PrivateRoute
							path="/vehicles/registration-documents/arrivals"
							component={DocumentArrivals}
							roles={[UserRole.AldUser]}
						/>
						<PrivateRoute
							path="/vehicles/search"
							component={Vehicles}
							roles={[UserRole.AldUser, UserRole.LotManager]}
						/>
						<PrivateRoute
							path="/vehicles/scan"
							component={VehicleScan}
							roles={[UserRole.AldUser, UserRole.LotManager]}
						/>
						<PrivateRoute
							path="/vehicles/arrivals/new"
							component={VehicleArrivalFormInput}
							roles={[UserRole.AldUser, UserRole.LotManager]}
						/>
						<PrivateRoute
							path="/vehicles/arrivals/:arrivalId"
							component={VehicleArrivalFormOutput}
							roles={[UserRole.AldUser, UserRole.LotManager]}
						/>
						<PrivateRoute
							path="/vehicles/arrivals"
							component={VehicleArrivalsOverview}
							roles={[UserRole.AldUser, UserRole.LotManager]}
						/>
						<PrivateRoute
							path="/vehicles/:id/after-shipping/"
							component={AfterShippingVehiclePage}
							roles={[UserRole.AldUser, UserRole.LotManager]}
						/>
						<PrivateRoute
							path="/vehicles/:id/dashboard"
							component={VehicleDashboard}
							roles={[UserRole.AldUser]}
						/>
						<PrivateRoute
							path="/vehicles/:id/base-data"
							component={BaseDataLayout}
							roles={[UserRole.AldManager]}
						/>
						<PrivateRoute
							path="/vehicles/:id/registration-documents/handovers"
							component={VehicleRegistrationDocumentHandover}
							roles={[UserRole.AldUser]}
						/>
						<PrivateRoute
							path="/vehicles/:id/process"
							component={VehicleProcess}
							roles={[UserRole.AldUser, UserRole.LotManager]}
						/>
						<PrivateRoute
							path="/vehicles/:id/history"
							component={VehicleHistory}
							roles={[UserRole.AldUser, UserRole.LotManager]}
						/>
						<PrivateRoute
							path="/vehicles/:id/picture-sets/new"
							component={NewVehiclePictureSet}
							roles={[UserRole.AldUser, UserRole.LotManager]}
						/>
						<PrivateRoute
							path="/vehicles/:id/picture-sets"
							component={VehiclePictureSets}
							roles={[UserRole.AldUser, UserRole.LotManager]}
						/>
						<PrivateRoute
							path="/vehicles/:vehicleId/orders/transports/new"
							component={NewTransportOrder}
							roles={[UserRole.AldUser, UserRole.LotManager]}
						/>
						<PrivateRoute
							path="/vehicles/:vehicleId/arrivals/new"
							component={VehicleArrivalFormInput}
							roles={[UserRole.AldUser, UserRole.LotManager]}
						/>
						<PrivateRoute
							path="/vehicles/:vehicleId/orders/assessments/new"
							component={AssessmentOrdersNew}
							roles={[UserRole.AldUser]}
						/>
						<PrivateRoute
							path="/vehicles/:vehicleId/orders/returns/new"
							component={ReturnOrdersNew}
							roles={[UserRole.AldUser]}
						/>
						<PrivateRoute
							path="/vehicles/:vehicleId/orders/customers/new"
							component={CustomerOrdersNew}
							roles={[UserRole.AldUser]}
						/>
						<PrivateRoute
							path="/vehicles/:id/orders/repairs"
							component={VehicleRepairOrders}
							roles={[UserRole.AldUser, UserRole.AldManager, UserRole.LotManager]}
						/>
						<PrivateRoute
							path="/vehicles/:id/orders"
							component={VehicleOrders}
							roles={[UserRole.AldUser, UserRole.LotManager]}
						/>
						<PrivateRoute path="/vehicles/:id/arms" component={ArmsVehicle} roles={[UserRole.AldUser]} />
						<PrivateRoute
							path="/vehicles/:id/dekra-assessments"
							component={DekraAssessmentsPage}
							roles={[UserRole.AldUser, UserRole.LotManager]}
						/>
						<PrivateRoute
							path="/vehicles/:id/assessments/new"
							component={NewAssessmentPage}
							roles={[UserRole.AldUser, UserRole.LotManager]}
						/>
						<PrivateRoute
							path="/vehicles/:id/assessments/:assessmentId"
							component={AssessmentPage}
							roles={[UserRole.AldUser, UserRole.LotManager]}
						/>
						<PrivateRoute
							path="/vehicles/:id/assessments"
							component={AssessmentsPage}
							roles={[UserRole.AldUser, UserRole.LotManager]}
						/>
						<PrivateRoute
							path="/vehicles/:id/documents"
							component={VehicleAllDocuments}
							roles={[UserRole.AldUser, UserRole.LotManager]}
						/>
						<PrivateRoute
							path="/vehicles/:id/services/new"
							component={VehicleServicesForm}
							roles={serviceRoles}
						/>
						<PrivateRoute
							path="/vehicles/:id/services/:serviceId"
							component={VehicleServiceDetails}
							roles={[UserRole.AldUser, ...serviceRoles]}
						/>
						<PrivateRoute
							path="/vehicles/:id/services"
							component={VehicleServices}
							roles={[UserRole.AldUser, UserRole.LotManager, ...serviceRoles]}
						/>
						<PrivateRoute
							path="/vehicles/:id/remarketing"
							component={RemarketingLayout}
							roles={[UserRole.SalesUser]}
						/>
						<PrivateRoute
							path="/vehicles/:id/leasing"
							component={LeasingLayout}
							roles={[UserRole.SalesUser]}
						/>
						<PrivateRoute
							path="/vehicles/:id"
							component={VehicleOverview}
							roles={[UserRole.AldUser, UserRole.LotManager]}
						/>
						<PrivateRoute
							path="/leasing/customers/:id"
							component={LeasingCustomerLayout}
							roles={[UserRole.AldManager]}
						/>
						<PrivateRoute
							path="/leasing/customers"
							component={LeasingCustomersLayout}
							roles={[UserRole.AldManager]}
						/>
						<PrivateRoute
							path="/inventories/:id/scan"
							component={InventoryScan}
							roles={[UserRole.AldUser, UserRole.LotManager]}
						/>
						<PrivateRoute
							path="/inventories/:id"
							component={InventoryVehicles}
							roles={[UserRole.AldUser, UserRole.LotManager]}
						/>
						<PrivateRoute
							path="/inventories"
							component={Inventories}
							roles={[UserRole.AldUser, UserRole.LotManager]}
						/>

						<PrivateRoute
							path="/compounds/:view"
							component={Lots}
							roles={[UserRole.AldUser, UserRole.LotManager]}
						/>

						<PrivateRoute
							path="/billings/new"
							component={NewBilling}
							roles={[UserRole.AldManager, UserRole.ServiceAccounting]}
						/>
						<PrivateRoute
							path="/billings/:id/services"
							component={BillingServices}
							roles={[UserRole.AldManager, UserRole.ServiceAccounting]}
						/>
						<PrivateRoute
							path="/billings/:id/clients/:client"
							component={BillingOverview}
							roles={[UserRole.AldManager, UserRole.ServiceAccounting]}
						/>
						<PrivateRoute
							path="/billings/:id"
							component={BillingOverview}
							roles={[UserRole.AldManager, UserRole.ServiceAccounting]}
						/>
						<PrivateRoute
							path="/billings"
							component={Billings}
							roles={[UserRole.AldManager, UserRole.ServiceAccounting]}
						/>

						<PrivateRoute
							path="/arrivaltransmissions/dad"
							component={DadArrivalTransmissionsLayout}
							roles={[UserRole.AldUser, UserRole.LotManager]}
						/>

						<PrivateRoute path="/work-sessions/new" component={NewWorkSession} roles={[UserRole.AldUser]} />
						<PrivateRoute path="/work-sessions" component={WorkSessions} roles={[UserRole.AldUser]} />

						<PrivateRoute
							path="/files/:hash/download"
							component={FileDownloadLayout}
							roles={[UserRole.AldUser, UserRole.TemporaryUser]}
						/>

						<PrivateRoute
							path="/compound-designer"
							component={CompoundDesigner}
							roles={[UserRole.AldAdmin]}
						/>

						<PrivateRoute
							path="/settings"
							component={UserSettingsLayout}
							roles={[UserRole.AldUser, UserRole.LotManager, UserRole.LogisticCompany]}
						/>

						<PrivateRoute path="/help" component={Help} roles={[UserRole.LotManager]} />

						<Route exact path="/" component={Index} />
						<Redirect to="/" />
					</Switch>
					<WorkSessionNavigation />
				</>
			)}
		</Suspense>
	);
};

import axios from 'axios';
import { useCallback, useState, MouseEvent } from 'react';
import { useDropzone } from 'react-dropzone';

import { AddAPhotoSharp, Close } from '@mui/icons-material';
import { Box, Grid, IconButton, LinearProgress, MenuItem, MenuList, Typography } from '@mui/material';

import Thumbnail from '../../../Components/Thumbnail';
import Tile from '../../../Components/Tiles/Tile';
import { Sale, SaleDocument, UserRole, Vehicle } from '../../../system/Domain';
import SalesDocumentTitle from './SalesDocumentTitle';
import useUser from "../../../system/useUser";

type ShippingDocument = "ShippingAttorney" | "ShippingPickup" | "Other" | "ShippingForm" | "IdentityCard" | "BillOfLading";

interface Props {
	sale: Sale
	vehicle: Vehicle
	exclude?: ShippingDocument[]
	onSaleChanged: (sale: Sale) => void
}

const PaperShipmentForm = (props: Props) => {
	const [, , hasRoles] = useUser();
	const [currentDocument, setCurrentDocument] = useState<ShippingDocument | null>(null);
	const [uploadProgress, setUploadProgress] = useState<number | null>(null);

	const allShippingDocuments: { [key in ShippingDocument]: string } = {
		"ShippingForm": "Übergabeprotokoll",
		"ShippingAttorney": "Abholvollmacht",
		"ShippingPickup": "Pickup",
		"IdentityCard": "Ausweisdokument",
		"BillOfLading": "Frachtpapiere",
		"Other": "Andere"
	};

	const createShippingDocumentsSubset = (excluded: ShippingDocument[]) => {
		let subset = {};
		Object.keys(allShippingDocuments).forEach((k) => {
			if (!excluded.some((ex) => ex == k)) {
				subset[k] = allShippingDocuments[k]
			}
		})

		return subset;
	};

	const shippingDocuments = props.exclude ? createShippingDocumentsSubset(props.exclude) : allShippingDocuments;

	const isLotManager = hasRoles(UserRole.LotManager);

	const handleSetDocument = (document: ShippingDocument) => () => {
		setCurrentDocument(document);
	};

	const onDrop = useCallback(async acceptedFiles => {
		if (!props.sale) return;
		if (!currentDocument) return;
		if (acceptedFiles.length !== 1) return;

		const file = acceptedFiles[0];

		const formData = new FormData();
		formData.append("file", file);
		formData.append("documentType", currentDocument);

		setUploadProgress(0);
		try {
			const { data: newSales } = await axios.post<Sale>(`/api/sales/${props.sale.id}/shipping-documents`,
				formData,
				{
					headers: {
						'Content-Type':
							'multipart/form-data'
					},
					onUploadProgress: evt => setUploadProgress(Math.ceil((evt.loaded / evt.total) * 100))
				}
			);

			setUploadProgress(100);
			props.onSaleChanged(newSales);
			setCurrentDocument(null);
		} finally {
			setUploadProgress(null);
		}
	}, [currentDocument]);

	const handleDeleteDocument = (document: SaleDocument) => async () => {
		const { data: newSales } = await axios.delete(`/api/sales/${props.sale!.id}/shipping-documents/${document.file.hash}`);
		props.onSaleChanged(newSales);
	};

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		multiple: false
	});

	const closeUpload = (e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		setCurrentDocument(null);
	};

	const documents = props.sale?.shipping?.documents || [];

	return (
		<Box>
			{currentDocument && uploadProgress && (
				<Box mt="10rem" mx="20%">
					<Typography align="center" gutterBottom>Bild wird hochgeladen</Typography>
					<LinearProgress variant="determinate" value={uploadProgress} />
				</Box>
			)}
			{currentDocument && !uploadProgress && (
				<div {...getRootProps()}>
					<input {...getInputProps()} capture />
					<Box display="flex" flexDirection="column" alignItems="center" mt="10rem">
						<Box display="flex" flexDirection="row">
							<Box display="flex" flexDirection="column" alignItems="center">
								<Typography variant="h4" gutterBottom>{shippingDocuments[currentDocument]}</Typography>
								<AddAPhotoSharp style={{ fontSize: "4rem", textAlign: "center" }} />
							</Box>
							<Box display="flex" flexDirection="column" mt={-2} ml={2} justifyContent={'flex-start'}>
								<IconButton onClick={closeUpload}>
									<Close />
								</IconButton>
							</Box>
						</Box>
					</Box>
				</div>
			)}
			{props.sale && !currentDocument && (
				<Tile title="Dokumente">
					<Grid container spacing={2}>
						{documents.map(d => (
							<Grid key={d.file.hash} item sx={
								!d.isCurrent && {
									filter: `grayscale(100%)`,
									opacity: 0.4,
									"&:hover": {
										filter: `grayscale(50%)`,
										opacity: 1
									}
								}
							}>
								<SalesDocumentTitle
									title={shippingDocuments[d.documentType]}
									onClose={isLotManager && d.isCurrent ? handleDeleteDocument(d) : null}
								/>
								<Thumbnail
									width={300}
									height={400}
									asyncUrl={`/api/storage/${d.file.hash}/thumbnail`}
									downloadUrl={`/api/storage/${d.file.hash}`}
									standalone
								/>
							</Grid>
						))}
						{isLotManager && (
							<Grid item>
								<SalesDocumentTitle title="Neues Dokument hinzufügen" />
								<Box minWidth={300} height={400} border={1} p={2}>
									<Box textAlign="center" mb={2}>
										<AddAPhotoSharp fontSize="large" />
									</Box>
									<MenuList>
										{Object.keys(shippingDocuments).map(k => {
											const shippingDocument = k as ShippingDocument;
											const value = shippingDocuments[shippingDocument];

											return (
												<MenuItem
													key={shippingDocument}
													onClick={handleSetDocument(shippingDocument)}
												>
													{value}
												</MenuItem>
											);
										})}
									</MenuList>
								</Box>
							</Grid>
						)}
					</Grid>
				</Tile>
			)}
		</Box>
	);
};

export default PaperShipmentForm;

import axios from "axios"
import { useCallback, useState } from "react"
import parseContentDispositionFilename from "system/parseContentDispositionFilename"
import fileDownload from "js-file-download"

export interface FormPrinting {
	printing: boolean
	printForm: (saleId: string, formIndex: string) => void
}

export default () => {
	const [printing, setPrinting] = useState(false);
	const printShipmentForm = useCallback(async (saleId: string, formIndex: string) => {
		setPrinting(true);

		try {
			const { data, headers } = await axios.get(`/api/sales/${saleId}/shipment-form/${formIndex}.pdf`, {
				responseType: "blob"
			});

			const filename = parseContentDispositionFilename(headers["content-disposition"]);

			fileDownload(data, filename);
		} finally {
			setPrinting(false);
		}
	}, []);

	const printer: FormPrinting = {
		printing,
		printForm: printShipmentForm
	}

	return printer;
};


import { Box, Typography } from "@mui/material";

interface Props {
	title: string
}

const EmptyElement = (props: Props) => {
	return (
		<Box sx={{ minHeight: "20px" }}>
			{props.title && (
				<Box sx={{ minHeight: "20px" }}>
					<Typography variant="body1" sx={{ fontSize: "0.8rem" }}>
					</Typography>
				</Box>
			)}
		</Box>
	);
};

export default EmptyElement;

import { Form } from "../../../../system/useForm";
import { Box, Grid, Typography } from "@mui/material";
import FormTiresAndRims from "Components/FormTiresAndRims";
import FormYesNo from "Components/FormYesNo";
import EmptySpace from "../EmptySpace";
import TwoSidedBox from "./TwoSidedBox";
import moment from "moment";
import dateFormats from "system/dateFormats";
import EmptyElement from "../../../../Components/EmptyElement";
import TitleElement from "../../../../Components/TitleElement";

const DAP04Wheels = ({ form, stepsProps }: { form: Form, stepsProps: any }) => {
	const rightSideInfo = stepsProps && `Angaben Eingang vom ${moment(stepsProps.getValue("dateUpdated")).format(dateFormats.date)}`;
	const hasReturnForm = stepsProps?.getValue("hasReturnForm") === true;

	return (
		<Grid item xs={12}>
			<TwoSidedBox
				leftHeadline={rightSideInfo && hasReturnForm && (
					<EmptyElement title={rightSideInfo} />
				)}
				left={
					<Grid container spacing={4}>
						<Grid item xs={12}>
							<Typography variant="subtitle1" sx={{ fontWeight: 'bolder' }}>Erster Satz</Typography>
						</Grid>
						<FormTiresAndRims
							name="installedTiresAndRims"
							form={form}
							canHaveNoRims />
						<Grid item xs={12}>
							<Typography variant="subtitle1" sx={{ fontWeight: 'bolder' }} mt={4}>Zweiter Satz</Typography>
						</Grid>
						<FormYesNo
							name="additionalTiresAndRims"
							label="Weitere Reifen und Felgen abgegeben?"
							form={form}
							options={{
								required: true
							}}
						/>
						{form.getValue("additionalTiresAndRims") && (
							<FormTiresAndRims
								name="suppliedTiresAndRims"
								form={form}
								canHaveNoRims />
						)}
					</Grid>
				}
				rightHeadline={rightSideInfo && hasReturnForm && (
					<TitleElement title={rightSideInfo} />
				)}
				right={stepsProps && hasReturnForm && (
					<Grid container spacing={4}>
						<Grid item xs={12}>
							<EmptySpace variant="subtitle1" />
						</Grid>
						<FormTiresAndRims
							name="installedTiresAndRims"
							form={stepsProps}
							canHaveNoRims
							disabled
						/>
						<Grid item xs={12}>
							<EmptySpace variant="subtitle1" mt={4} />
						</Grid>
						<FormYesNo
							name="additionalTiresAndRims"
							label="Weitere Reifen und Felgen abgegeben?"
							form={stepsProps}
							disabled
						/>
						{stepsProps.getValue("additionalTiresAndRims") && (
							<FormTiresAndRims
								name="suppliedTiresAndRims"
								form={stepsProps}
								canHaveNoRims
								disabled
							/>
						)}
					</Grid>
				)}
			/>
		</Grid>
	);
};

export default DAP04Wheels;
